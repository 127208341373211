<meta http-equiv="Cache-Control" content="no-cache, no-store, must-revalidate">
<meta http-equiv="Pragma" content="no-cache">
<meta http-equiv="Expires" content="0">

<ng-template #instructionsTemplate><ng-content select="[projectedInstructions]"></ng-content></ng-template>

<!-- DESIGN MODE -->
<div *ngIf="this.IsInDesignMode" class="grid-design-div">
    <!-- Display my label. -->
    <div class="design-mode-field-label" *ngIf="this.DisplayName">{{this.DisplayName}}</div>
    <!-- Define a place to inject instructions, if any. -->
    <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>

    <app-grid-field-designer [gridFormFieldComponent]="this"></app-grid-field-designer>

    <!-- Define a design mode menu. -->
    <mat-menu #gridCellContextMenu="matMenu">
        <ng-template matMenuContent let-row="row">
            <button mat-menu-item
                    [disabled]="false"
                    (click)="this.gridCellContextMenuItemClicked($event,'Properties ...')">
                Properties ...
            </button>
            <mat-divider></mat-divider>
            <button mat-menu-item
                    [disabled]="false"
                    (click)="this.gridCellContextMenuItemClicked($event,'Delete')">
                Delete
            </button>
        </ng-template>
    </mat-menu>

    <!-- Define a menu trigger. -->
    <div style="visibility: hidden; position: fixed"
         [style.left]="this.ContextMenuPositionX"
         [style.top]="this.ContextMenuPositionY"
         [matMenuTriggerFor]="gridCellContextMenu">
    </div>
</div>

<!-- PREVIEW/INSTANCE MODE -->
<div *ngIf="this.IsInPreviewOrInstanceMode" class="preview-and-instance-modes-div">
    <div class="field-label" *ngIf="this.DisplayName">{{this.DisplayName}}</div>

    <ng-container *ngTemplateOutlet="instructionsTemplate"></ng-container>
    <app-grid-field-editor [formInstance]="FormInstance"
                           [gridFormFieldComponent]="this"
                           [isFootnotesGrid]="isFootnotesGrid"
                           (editorGridValidation)="this.handleEditorGridValidation($event)"
                           (conditionalLogicColumnDefsFilter)="this.handleConditionalLogicColumnDefsFilter($event)"></app-grid-field-editor>
</div>
