<div style="padding: 15px; background-color: #fafafa; margin-top: 64px; height: 100%;">
    <div *ngIf="Url != '/unavailable'">
        {{Message}}
    </div>

    <div *ngIf="Url == '/unavailable'" class="unavailable">
        <p>
            From 7pm on Friday, February 2nd to 7am on Monday, February 5th the OMB Collect application
        (formerly MAX vNext Collect) will be offline for scheduled maintenance.<br/>
        If you are receiving this message outside that timeframe, please contact <a href="mailto:maxsupport@max.gov">maxsupport&#64;max.gov</a>.</p>
        <br/>
        <p>
            Thank you,<br />
            The OMB Collect Team
        </p>
    </div>
</div>
