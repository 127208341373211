// code commented out with "// Commented out for VNEXT-1429 (remove old grid)" note above it
// can be deleted once we're sure this is all working ok

import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ComponentFactoryResolver, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, QueryList, ViewChild, ViewChildren, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { environment } from '../../../../../environments/environment';
import { CurrentUserService } from '../../../../security/current-user.service';
import { GridPasteKendoDialog } from '../../../../shared/dialogs/grid-paste-kendo/grid-paste-kendo.dialog';
import { GeocodeDialog, GeocodeDialogModel } from '../../../dialogs/geocode/geocode-dialog.component';
import { DynamicComponentHostDirective } from '../../../directives/dynamic-content-host.directive';
import { IFieldDefinitionLogic } from '../../../interfaces/ifield-definition-logic.interface';
import { FieldDefinition } from '../../../models/form-builder/field-definition.model';
import { FormField } from '../../../models/form-builder/form-field.model';
import { FormInstanceElement } from '../../../models/form-builder/form-instance-element.model';
import { GridConfig } from '../../../models/grid/grid-config.model';
import { GridFilter } from '../../../models/grid/grid-filter.model';
import { GridFormInstanceElementWrapper } from '../../../models/grid/grid-form-instance-element-wrapper.model';
import { GridRowDef } from '../../../models/grid/grid-row.model';
import { LoadingDataProgressInfo } from '../../../models/grid/loading-data-progress.model';
import { CommunicationService } from '../../../services/communication.service';
import { CurrentSiteService } from '../../../services/current-site.service';
import { FieldDefinitionService } from '../../../services/field-definition.service';
import { FormFieldTypeAndNameService } from '../../../services/form-field-type-and-name.service';
import { GeocodeService } from '../../../services/geocode.service';
import { FormFieldBaseComponent } from '../form-field-base/form-field-base.component';
import { GRID_ROW_ID_KEY, GridDesignerEditorBase } from '../grid-field-designer/grid-designer-editor-base';

// BEGIN KENDO GRID IMPORTS.
import { HttpClient } from '@angular/common/http';
import {
    AddEvent,
    CancelEvent,
    CellClickEvent,
    CommandColumnComponent,
    DetailCollapseEvent,
    DetailExpandEvent,
    GridComponent,
    MultipleSortSettings,
    RemoveEvent,
    RowReorderEvent,
    SaveEvent
} from "@progress/kendo-angular-grid";
import { State } from "@progress/kendo-data-query";
import { AsyncJob } from '../../../models/async-job.model';
import { FormFieldPropertyEnum } from '../../../models/form-builder/form-field-property-enum.model';
import { AsyncJobService } from '../../../services/async-job.service';
import { ExportDataService } from '../../../services/export-data.service';
import { KendoGridService } from '../../../services/kendo-grid.service';
import { DataUsedWithKendoGrid } from './data-used-with-kendo-grid';
// END KENDO GRID IMPORTS. 
import { MonitorExcelImportToGridHelper } from '../../../dialogs/async-job-base/monitor-excel-import-to-gride-helper';
import { ImportGridDataDialog, ImportGridDataDialogInitInfo } from '../../../dialogs/import-grid-data/import-grid-data.dialog';
import { IKendoGridDataSpy, KendoGridHelper } from '../../../kendo-grid-helper';
import { GridRowViewModel, IFormContentElement } from '../../../models/form-builder/grid-row.model';
import { ComponentAndFormField } from '../../../models/grid/component-and-form-field-model';
import { FormInstance } from '../../../models/site-content/form-instance.model';
import { TargetFieldConditionalLogicInstructions } from '../../../models/site-metadata/target-field-logic-instructions';
import { FieldConditionalLogicService } from '../../../services/field-conditional-logic.service';
import { FlexibleSelectionFieldService } from '../../../services/flexible-selection-field.service';
import { ImportDataService } from '../../../services/import-data.service';
import { FileInputUtil } from '../../../utility-classes/file-input.util';
import { take } from 'rxjs/operators';

declare let $: any; // jQuery

const DEFAULT_UNSELECTED_ROW_HEIGHT: number = 48;

// Implement a class to handle column totals.
interface IMapOfTotalsByCellName {
    [cellName: string]: number;
}
class GridColumnTotalsHelper implements IKendoGridDataSpy {
    // Properties.
    private dataRows: any[] = null;
    private numericColumnDefs: FormField[] = [];
    private cellTotals: IMapOfTotalsByCellName = {};

    // Constructor.
    public constructor(private fieldDefinitionService: FieldDefinitionService, private columnDefs: FormField[]) {
        if (columnDefs != null) {
            for (let index: number = 0; index < columnDefs.length; index++) {
                let columnDef: FormField = columnDefs[index];
                let fieldDef: FieldDefinition = this.fieldDefinitionService.getFieldDefinition(columnDef.fieldDefinitionClassName);
                if (fieldDef.isNumeric)
                    this.numericColumnDefs.push(columnDef);
            }
        }
    }

    // IKendoGridDataSpy method.
    public dataLoaded(loadedData: any[]): void {
        this.dataRows = loadedData;
        this.cellTotals = GridColumnTotalsHelper.calculateAllColumnTotals(this.fieldDefinitionService, this.numericColumnDefs, loadedData);
    }

    public cellValueChanged(rowIndex: number, columnDef: FormField, value: FormInstanceElement): void {
        if ((rowIndex < this.dataRows.length) && (rowIndex >= 0)) {
            let fieldDef: FieldDefinition = this.fieldDefinitionService.getFieldDefinition(columnDef.fieldDefinitionClassName);
            let numericValue: any = value[fieldDef.formInstanceElementPropertyName];
            let rowValues: IMapOfTotalsByCellName = this.dataRows[rowIndex];
            if (rowValues != null)
                rowValues[columnDef.name] = numericValue;

            let total: number = GridColumnTotalsHelper.calculateColumnTotal(columnDef, fieldDef, this.dataRows);
            this.cellTotals[columnDef.name] = total;
        }
    }

    public getFooterValue(columnDef: FormField): string {
        let cellTotal: string = this.cellTotals[columnDef.name] != null ? this.cellTotals[columnDef.name].toString() : '';
        return cellTotal;
    }

    // Helper methods.
    private static calculateAllColumnTotals(fieldDefinitionService: FieldDefinitionService, numericColumnDefs: FormField[], rowData: any[]): IMapOfTotalsByCellName {
        let cellTotals: IMapOfTotalsByCellName = {};

        for (let index: number = 0; index < numericColumnDefs.length; index++) {
            let columnDef: FormField = numericColumnDefs[index];
            let fieldDef: FieldDefinition = fieldDefinitionService.getFieldDefinition(columnDef.fieldDefinitionClassName);
            let total: number = GridColumnTotalsHelper.calculateColumnTotal(columnDef, fieldDef, rowData);
            cellTotals[columnDef.name] = total;
        }

        return cellTotals;
    }
    private static calculateColumnTotal(columnDef: FormField, fieldDef: FieldDefinition, rowData: any[]): number {
        let total: number = 0;

        for (let index: number = 0; index < rowData.length; index++) {
            let row: any = rowData[index];
            let cellValue: any = row[columnDef.name];
            if (cellValue != null) {
                let value: number = fieldDef.customLogicHandler.getNumericValueFrom(cellValue.toString()); //parseFloat(cellValue.toString());
                if (!isNaN(value))
                    total += value;
            }
        }

        return total;
    }
}

@Component({
    selector: 'app-grid-field-editor',
    templateUrl: './grid-field-editor.component.html',
    styleUrls: ['../grid-form-field/grid-form-field.component.scss', './grid-field-editor.component.scss'],
    encapsulation: ViewEncapsulation.None, //enable CSS overrides
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({ height: '0px', minHeight: '0' })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ]
})
export class GridFieldEditorComponent extends GridDesignerEditorBase implements OnInit {
    @Input() formInstance: FormInstance;

    // Properties.
    // This references all of the templates in a row which look like this in the HTML:
    // <ng-template dynamic-component-host name="t-{{gridRow.RowIndex}}-{{hshColumnDef.fieldOrder}}"></ng-template>
    @ViewChildren(DynamicComponentHostDirective, { read: DynamicComponentHostDirective }) dynamicComponentHosts: QueryList<DynamicComponentHostDirective>;

    //@ViewChildren(FormFieldBaseComponent, { read: FormFieldBaseComponent })formFieldComponents: QueryList<FormFieldBaseComponent>;

    // Properties.
    // Define instance data used with a MatPaginator.
    @ViewChild('matPaginatorMatFooter', { read: MatPaginator }) matPaginator;

    //VNEXT-894: KLW - Needed for grid validation
    @Output() editorGridValidation = new EventEmitter();
    private newRowIsBeingAdded: boolean = false;

    @Output() conditionalLogicColumnDefsFilter = new EventEmitter();

    //VNEXT-980: KLW - Property to set the number of Kendo grid rows to display
    private rowHeight: number = 23.14; // 23.14;
    private toolbarHeadersFilters = 107; // height of grid with no rows

    private detailRowHeight: number = 0;
    private rowDetailPanel: ElementRef;
    private saving: boolean;
    private extraHeightWhenEditingRow: number = 0;
    @ViewChild('rowDetailPanel') set content(content: ElementRef) {
        if (content) this.rowDetailPanel = content;
    }
    @ViewChild('selectExcelFile') selectExcelFile; // Used to load an Excel file to import its data.

    public dataUsedWithKendoGrid: DataUsedWithKendoGrid = new DataUsedWithKendoGrid();
    private helper = new KendoGridHelper();
    public formInstanceElementId: number; // the Id of the FormInstanceElement which contains the grid data
    private formInstanceId: number;
    private formFieldId: number;
    private kendoGridInstance: GridComponent;
    private showGridFilter: boolean = true;

    //TEAMS-838: KLW - Create a form group to be used in validation 
    public formGroup: UntypedFormGroup;
    public validationCount = 0;

    //VNEXT-1540: KLW - Flag for resizable attribute of the Kendo grid
    public resizable: boolean = false;

    private loadingDataProgress: LoadingDataProgressInfo = new LoadingDataProgressInfo();
    private kendoGridService: KendoGridService;
    private gridValidity: string;
    private rowEditInProgress: boolean;

    private filterPresets: GridFilter[] = [];
    public presetFilterSelected: string;

    // Constructor.
    public constructor(private currentSiteService: CurrentSiteService,
        private flexibleSelectionFieldService: FlexibleSelectionFieldService,
        formFieldTypeAndNameService: FormFieldTypeAndNameService,
        resolver: ComponentFactoryResolver,
        fieldDefinitionService: FieldDefinitionService,
        private dialog: MatDialog,
        private currentUserService: CurrentUserService,
        private exportDataService: ExportDataService,
        private geocodeService: GeocodeService,
        private asyncJobService: AsyncJobService,
        private importDataService: ImportDataService,
        private communicationService: CommunicationService,
        private fieldConditionalLogicService: FieldConditionalLogicService,
        private httpClient: HttpClient,
        private ngZone: NgZone) {
        super(formFieldTypeAndNameService, fieldDefinitionService, resolver);
    }

    private createFormGroup() {
        this.formGroup = new UntypedFormGroup({});
        this.validationCount = 0;
    }

    public get validationFormGroup(): boolean {
        var retVal: boolean = false;
        if (this.formGroup) {
            retVal = !this.formGroup.valid;
        }
        return retVal;
    }

    public get FormInstance(): FormInstance {
        return this.formInstance;
    }

    // pharvey - 5/29/2024 - VNEXT-1294 - this is triggered from FormRenderer and CommunicationService when a user clicks the main Save button on a Form
    public saveAnyUnsavedRows(loc: string): Promise<any> {
        if (this.rowEditInProgress) {
            let sender = this.kendoGridInstance;

            let selectedGridRow = this.dataUsedWithKendoGrid.selectedGridRow;
            let rowIndex = this.dataUsedWithKendoGrid.editedRowIndex;
            let isNew = this.dataUsedWithKendoGrid.CurrentDataItem == null;
            let dataItem = this.dataUsedWithKendoGrid.CurrentDataItem;
            let cols = sender.columns['_results'];

            this.newRowIsBeingAdded = false;
            this.rowEditInProgress = false;

            this.saving = true;
            return this.kendoGridService.addOrUpdateRow(
                this.formInstanceElementId,
                this.formInstanceId,
                this.formFieldId,
                selectedGridRow,
                rowIndex,
                cols,
                dataItem,
                isNew,
                this.gridFormFieldComponent.GridConfig,
                this.GridFormFieldComponent,
                sender.data['total']
            ).then(x => {
                this.extraHeightWhenEditingRow = 0;
                this.closeAnyExistingRowEditor(sender, rowIndex);
                this.saving = false;
                return x;
            });
        } else {
            return new Promise<any>((resolve, reject) => {
                resolve(null);
            });
        }
    }

    public handleWrapperFormControlCreated(control: any) {
        // pharvey - if a control has a status of "DISABLED" it means it's readonly, so skip it to avoid spurious validation errors
        if (this.formGroup && control.status != FormFieldPropertyEnum.DISABLED) {
            this.formGroup.addControl("KendoValidation" + this.validationCount.toString(), control);
            this.validationCount++;
        }
    }

    // Life cycle methods.
    public ngOnInit(): void {
        this.communicationService.registerGrid(this);

        // LOOK INTO GETTING THIS INJECT AT THE COMPONENT LEVEL (look at providers) SO WE DON"T HAVE TO MANUALLY INSTANTIATE IT HERE
        this.kendoGridService = new KendoGridService(this.httpClient, this.fieldDefinitionService);
        // TO DO:  MOVE 'preview'/'instance' mode-related ngOnInit() code from grid-form-field.component.ts. to this method.

        // 05-02-2024 note:  added the following code to handle grid column totals, if so configured.
        if (this.ShowNumericTotalsFooter) {
            let totalsHelper: GridColumnTotalsHelper = new GridColumnTotalsHelper(this.fieldDefinitionService, this.gridFormFieldComponent.GridColumnDefs);
            this.helper.DataSpy = totalsHelper;
        }

        this.adjustToolbarHeadersFiltersHeight();

        //apply preset filters
        let gridField = this.formInstance.form.formFields.find(g => g.id == this.gridFormFieldComponent.FormField.id);
        if (gridField) {
            let filterPresetsJson: GridFilter[] = JSON.parse(gridField.formTemplateInstancesGridJsonConfig);
            if (filterPresetsJson) {
                let filters = filterPresetsJson["presetGridFilters"];
                if (filters && filters[0].filterName) {
                    this.filterPresets = filters;
                }
            }
        }
    }

    public ngAfterViewInit(): void {
        this.hideGridBodyIfSoConfigured();
    }

    // Override applicable base class/FormFieldComponent methods.
    public isCompoundObjectComponent(): boolean {
        // Note:  this method is also used in apply conditional logic.
        return true;
    }
    public resetConditionalLogicSettings(): void {
        // Note:  reset cached column settings.
        //this.gridFormFieldComponent.GridConfig.CachedGridColumnDefs = null;
        this.gridFormFieldComponent.GridConfig.ConditionalLogicColumnDefs = null;
    }
    public applyChildFieldAttributes(childFieldName: string, showChildField: boolean, childFieldIsReadOnly: boolean, childFieldIsRequired: boolean): void {
        let gridConfig: GridConfig = this.gridFormFieldComponent.GridConfig;
        let columnDefs: FormField[] = gridConfig.ColumnDefs;

        let childFieldColumnDef: FormField = columnDefs.find(cd => cd.name == childFieldName);
        if (childFieldColumnDef != null) {
            //if (childFieldIsReadOnly)
            //childFieldColumnDef.readOnly = true;
            childFieldColumnDef.readOnly = childFieldIsReadOnly;
            childFieldColumnDef.required = childFieldIsRequired;

            let columnDefsToFilter: FormField[] = gridConfig.CachedGridColumnDefs != null ? gridConfig.CachedGridColumnDefs : columnDefs;
            // Note:  if the target column is not already included in columnDefsToFilter, we need to add it.
            let filteredColumnDefs: FormField[] = null; //  !showChildField ? columnDefsToFilter.filter(cd => cd.name != childFieldName) : columnDefsToFilter;
            if (showChildField && (columnDefsToFilter.find(cd => cd.name == childFieldColumnDef.name) == null)) {
                columnDefsToFilter.push(childFieldColumnDef);
                filteredColumnDefs = columnDefsToFilter;
            } else if ((!showChildField) && (columnDefsToFilter.find(cd => cd.name == childFieldColumnDef.name) != null)) {
                filteredColumnDefs = columnDefsToFilter.filter(cd => cd.name != childFieldColumnDef.name);
            } else {
                filteredColumnDefs = columnDefsToFilter;
            }
            //gridConfig.CachedGridColumnDefs = filteredColumnDefs;
            this.conditionalLogicColumnDefsFilter.emit(filteredColumnDefs);
            //gridConfig.ConditionalLogicColumnDefs = filteredColumnDefs;

            if ((this.gridFormFieldComponent != null) && (this.gridFormFieldComponent.RuntimeMetadata != null) && (this.gridFormFieldComponent.RuntimeMetadata.AllComponents != null)) {
                for (let index: number = 0; index < this.gridFormFieldComponent.RuntimeMetadata.AllComponents.length; index++) {
                    let componentInfo: ComponentAndFormField = this.gridFormFieldComponent.RuntimeMetadata.AllComponents[index];
                    if (childFieldColumnDef.id == componentInfo.FormField.id) {
                        componentInfo.Component.formFieldUpdated();
                    }
                }
            }
        }
    }

    private applyFieldConditionalLogicInstructions(targetFieldInstructions: TargetFieldConditionalLogicInstructions[]): void {
        // Clear any cached/saved column definitions.
        this.gridFormFieldComponent.GridConfig.CachedGridColumnDefs = null;

        // Apply any instructions.
        if (targetFieldInstructions != null) {
            let instructionsForFormRenderer: TargetFieldConditionalLogicInstructions[] = [];

            for (let index: number = 0; index < targetFieldInstructions.length; index++) {
                let targetFieldInstruction: TargetFieldConditionalLogicInstructions = targetFieldInstructions[index];

                if (targetFieldInstruction.targetFieldId == this.gridFormFieldComponent.FormField.id) {
                    this.applyChildFieldAttributes(targetFieldInstruction.childFieldName, targetFieldInstruction.showTargetField, !targetFieldInstruction.makeTargetFieldEditable, targetFieldInstruction.makeTargetFieldRequired);
                } else {
                    instructionsForFormRenderer.push(targetFieldInstruction);
                }
            }

            if (instructionsForFormRenderer.length > 0) {
                // Note:  these are instructions that the form renderer, my parent, has to apply, so let my
                //        parent know that there are conditional logic instructions that need to be applied.
                this.gridFormFieldComponent.conditionalLogicInstructions.emit(instructionsForFormRenderer);
            }
        }
    }

    // Define accessor methods called by my HTML code.
    public get Helper(): KendoGridHelper {
        return this.helper;
    }

    public get IsLoading() {
        return this.kendoGridService.Loading;
    }

    public get HideRowDelete(): boolean {
        return this.gridFormFieldComponent.FormField.disableGridRowDeleteForNonAdmins && (!this.currentSiteService.Site.siteIsAdministerable);
    }

    public get IsReadOnlyGrid() {
        return (this.ReadOnly);
    }

    public get ModifyRowDisabled() {
        if (this.ReadOnly) return true;
        return this.HasFixedRowHeadings;
    }

    public get HasFixedRowHeadings() {
        return this.gridFormFieldComponent.FormField.fixedFirstColumnJson != null;
    }

    public get HideFormFieldBody(): boolean {
        return this.gridFormFieldComponent.FormField.hideFormFieldBody;
    }

    public parentReceivedFormInstanceElement(formInstanceElement: FormInstanceElement): void {
        this.formInstanceElementId = formInstanceElement.id;
        this.formFieldId = formInstanceElement.formFieldId;
        this.formInstanceId = formInstanceElement.formInstanceId;
    }

    public getNumericFooterTotalValueFor(colIndex: number, columnDef: FormField): string {
        let totalsHelper: GridColumnTotalsHelper = <GridColumnTotalsHelper>this.helper.DataSpy;
        return totalsHelper != null ? totalsHelper.getFooterValue(columnDef) : '';
    }

    public get FilterPresets(): GridFilter[] {
        return this.filterPresets;
    }

    public selectPresetFilter(e): void {
        this.helper.handleClearFilters();
        if (e?.filters) {
            e.filters.forEach(f => {
                this.helper.Filters.filters.push({
                    field: f.columnName, // 'c1',
                    operator: f.filterOperatorAbbreviation, //'eq',
                    value: f.filterMatchValue // 'Agency A'
                })
            })
            this.helper.Filters.logic = "and";
        }
    }

    public filterChange(): void {
        this.presetFilterSelected = "";
    }

    public ShowGeocodeGrid(): boolean {
        let apiUrl = environment.apiUrl.toLowerCase();
        if (apiUrl.indexOf('.test.') < 0 && apiUrl.indexOf('.stage.') < 0 && apiUrl.indexOf('.sandbox.') < 0) {
            if (this.FormFieldId == 12105) return true; //VNEXT-1275: enable geocoding for "Project Description Table - Geography" grid in production Environmental Justice Data Call site ID 192
            return false; //disable in production
        }

        return true;
    }

    public geoCodeGrid(): void {
        let gridCols = [];
        this.GridColumnDefs.forEach(gcd => {
            gridCols.push({ id: gcd.id, name: gcd.name });
        })

        let dialogRef = this.dialog.open(GeocodeDialog, {
            width: "500px",
            data: new GeocodeDialogModel(
                `Geocode Grid Data`,
                gridCols
            )
        });

        dialogRef.afterClosed().subscribe(retVal => {
            if (retVal) {
                this.geocodeService.GeocodeGrid(this.formInstanceId, this.formInstanceElementId).then(resp => {
                    this.flagDirty();
                    this.kendoGridService.read(this.gridFormFieldComponent.GridConfig, this.fieldDefinitionService, this.gridFormFieldComponent, this.formInstanceElementId, this.dataUsedWithKendoGrid.gridState);
                });
            }
        });
    }

    public showPasteAreaKendo(): void {
        // Show dialog for pasted Excel data.
        let dialogConfig: MatDialogConfig = new MatDialogConfig();
        dialogConfig.hasBackdrop = true;
        dialogConfig.width = '600px';

        let gridHasData: boolean = false;

        dialogConfig.data = { colCount: this.gridFormFieldComponent.GridConfig.ColumnCount, rowCount: this.Helper.TotalRowCount, gridHasData: this.Helper.TotalRowCount > 0, pasteMode: 'replace', pasteData: '' };
        const dialogRef = this.dialog.open(GridPasteKendoDialog, dialogConfig);
        dialogRef.disableClose = true;
        dialogRef.beforeClosed().subscribe(() => dialogRef.close(dialogConfig.data));
        dialogRef.afterClosed().subscribe(replaceGridData => {
            if ((replaceGridData != null) && (replaceGridData.pasteData != null) && (replaceGridData.pasteData.trim() != '')) {
                let replaceGridDataFlag: boolean = replaceGridData.pasteMode == 'replace';
                this.importDataService.importExcelPasteDataIntoGrid(replaceGridData.pasteData, this.formInstanceElementId, replaceGridDataFlag).then(asyncJob => {
                    let jobMonitor: MonitorExcelImportToGridHelper = new MonitorExcelImportToGridHelper(this.asyncJobService);
                    jobMonitor.startMonitoringJob(asyncJob, this.jobCompletedOrUpdatedArrowFunction);

                    let dialogInitInfo = new ImportGridDataDialogInitInfo(this.formInstanceElementId, null, null, asyncJob, replaceGridDataFlag);

                    let dialogConfig: MatDialogConfig = new MatDialogConfig();
                    dialogConfig.hasBackdrop = true;
                    dialogConfig.width = '500px';
                    dialogConfig.height = '590px';
                    dialogConfig.data = dialogInitInfo;

                    const dialogRef = this.dialog.open(ImportGridDataDialog, dialogConfig);

                    dialogRef.afterClosed().subscribe(result => {
                        // If the result is non-null, reload the grid's data.
                        if (result != null) {
                            this.flagDirty(); // Indicate that there is new data that has not yet been saved.
                            this.kendoGridService.readPending(
                                this.gridFormFieldComponent.GridConfig,
                                this.fieldDefinitionService,
                                this.gridFormFieldComponent,
                                this.formInstanceElementId,
                                this.dataUsedWithKendoGrid.gridState
                            );
                        }
                    });

                });
            }
        });

        return;
    }

    public importGridDataClicked(): void {
        this.selectExcelFile.nativeElement.click();
    }
    public exportGridDataClicked() {
        this.exportDataService.exportGridDataAsExcelSpreadsheet(this.FormInstanceElementId).then(asyncJob => {
            this.asyncJobService.monitorJobUntilCompletion(asyncJob, 250, this.openExportedDataExcelSpreadsheet);
        });
    }

    private openExportedDataExcelSpreadsheet = (asyncJob: AsyncJob, successFlag: boolean) => {
        let downloadFileName: string = `grid_${this.FormInstanceElementId}_data.xlsx`;
        let downloadFileURL: string = this.exportDataService.getResultFileDownloadUrl(asyncJob, downloadFileName);

        window.open(downloadFileURL, '_blank');
    }

    public onExcelFileChanged(eventData: any): void {
        if (this.selectExcelFile.nativeElement.files?.length > 0) {
            let file: File = this.selectExcelFile.nativeElement.files[0];
            let filename: string = file.name;

            let asyncJob: AsyncJob = null; // As we are no longer initiating the job outside of the dialogue.

            let dialogInitInfo = new ImportGridDataDialogInitInfo(this.formInstanceElementId, file, filename, asyncJob);

            let dialogConfig: MatDialogConfig = new MatDialogConfig();
            dialogConfig.hasBackdrop = true;
            dialogConfig.width = '500px';
            dialogConfig.height = '590px';
            dialogConfig.data = dialogInitInfo;

            const dialogRef = this.dialog.open(ImportGridDataDialog, dialogConfig);

            dialogRef.afterClosed().subscribe(result => {
                // Always clear the file control.  If the grid was updated, indicated by a non-null result, refresh the grid.
                FileInputUtil.clearFileSelection(this.selectExcelFile);
                if (result != null) {
                    this.flagDirty(); // Indicate that there is new data that has not yet been saved.
                    this.kendoGridService.readPending(
                        this.gridFormFieldComponent.GridConfig,
                        this.fieldDefinitionService,
                        this.gridFormFieldComponent,
                        this.formInstanceElementId,
                        this.dataUsedWithKendoGrid.gridState
                    );
                }
            });
        }
    }

    public SortSettings: MultipleSortSettings = {
        mode: "multiple",
        initialDirection: "desc",
        allowUnsort: true,
        showIndexes: true,
    };

    public get GridIsFilterable(): boolean {
        return !this.gridFormFieldComponent.FormField.hideGridFiltering;
    }
    public FilterType(columnDef: FormField): string {
        let handler: IFieldDefinitionLogic = this.fieldDefinitionService.getFieldDefinition(columnDef.fieldDefinitionClassName).customLogicHandler;
        let filterType = handler.filterType();
        return filterType;
    }

    public turnOnGridFilter() {
        this.showGridFilter = true;
    }

    public turnOffGridFilter() {
        this.Helper.handleClearFilters();
        this.presetFilterSelected = '';
        this.showGridFilter = false;
    }

    public ColumnIsFilterable(columnDef: FormField): boolean {
        // This is temporary conditional logic.
        // The isFilterable() method of Multi value fieldDefs now returns "true" in order for them to enabled on the home page grids, but they are still not enabled on
        // regular grids yet

        if (!this.showGridFilter) return false;

        if (columnDef.fieldDefinitionClassName.indexOf('MultiDropDownFieldDefinition') > -1 || columnDef.fieldDefinitionClassName.indexOf('MultiCheckBoxFieldDefinition') > -1) {
            return false;
        }
        let handler: IFieldDefinitionLogic = this.fieldDefinitionService.getFieldDefinition(columnDef.fieldDefinitionClassName).customLogicHandler;
        return handler.isFilterable();
    }

    public get ShowGridFilter(): boolean {
        return this.showGridFilter;
    }

    public IsSortable(columnDef: FormField): boolean {
        if (columnDef.fieldDefinitionClassName.indexOf('MultiDropDownFieldDefinition') > -1 || columnDef.fieldDefinitionClassName.indexOf('MultiCheckBoxFieldDefinition') > -1) {
            return false;
        }
        let handler: IFieldDefinitionLogic = this.fieldDefinitionService.getFieldDefinition(columnDef.fieldDefinitionClassName).customLogicHandler;
        return handler.isFilterable();
    }

    public GetColumnWidth(columnDef: FormField): number {
        return columnDef.gridColumnWidth > 0 ? columnDef.gridColumnWidth : 180;
    }

    // Determines if the "+" sign should be shown to allow the row to be expanded and show fields that are configured to display in the detail area
    public GridHasDetail(): boolean {
        let res = this.GridColumnDefs.filter(x => { return ['ShowInExpandedDetailOnly', 'ShowInRowDataAndDetail'].indexOf(x.gridColumnBehavior) > -1 });
        return res.length > 0;
    }

    public ShowColumnInRow(columnDef: FormField): boolean {
        return !columnDef.gridColumnBehavior || (['ShowInRowDataOnly', 'ShowInRowDataAndDetail'].indexOf(columnDef.gridColumnBehavior) > -1);
    }

    public ShowColumnInDetail(columnDef: FormField): boolean {
        return ['ShowInExpandedDetailOnly', 'ShowInRowDataAndDetail'].indexOf(columnDef.gridColumnBehavior) > -1;
    }

    public ShowColumnAs(columnDef: FormField): string {
        let logicHandler: IFieldDefinitionLogic = this.fieldDefinitionService.getFieldDefinition(columnDef.fieldDefinitionClassName).customLogicHandler;
        return logicHandler.whenInGridShowAs();
    }

    //VNEXT-894: KLW - Needed to show the contents of the HTML link form field as a URL
    public ShowColumnAsURLLink(columnDef: FormField): boolean {
        var retVal = false;

        let handler: IFieldDefinitionLogic = this.fieldDefinitionService.getFieldDefinition(columnDef.fieldDefinitionClassName).customLogicHandler;

        if (handler) {
            if (handler.isURLLink()) {
                retVal = true
            }
        }
        return retVal;
    }

    //Kevin
    public test(colId: number): FormInstanceElement {
        return this.DataUsedWithKendoGrid.selectedGridRow?.FormInstanceElementWrappers[colId].formInstanceElement
    }

    // TODO: It should be ok to remove this -- it's no longer being used since the KendoGridRemoteBinding directive now manages state updates
    public onStateChange(state: State): void {
        this.dataUsedWithKendoGrid.gridState = state;
    }

    // Handles clicking the "Add Row" button
    public addRowToUI({ sender }: AddEvent): void {
        this.kendoGridInstance = sender;
        this.flexibleSelectionFieldService.getFlexibleSelectFieldInstructionsForGridRow(this.formInstanceId, null, this.GridColumnDefs, this.formInstance);

        this.extraHeightWhenEditingRow = 100; // TODO - set it by calculating the actual row height
        this.closeAnyExistingRowEditor(sender);
        this.rowEditInProgress = true;

        // in order for the new row to display correctly, need to construct a GridRowDef and set it as this.DataUsedWithKendoGrid.selectedGridRow
        // (This enables the two-way databinding to work in the template ... [(ngModel)]="this.DataUsedWithKendoGrid.selectedGridRow?.FormInstanceElementWrappers[iColIndex].formInstanceElement")
        let gridRow = new GridRowDef(this.gridFormFieldComponent.GridConfig, 0, 0, 0, 0, this.GridFormFieldComponent);
        this.DataUsedWithKendoGrid.selectedGridRow = gridRow;

        // GridComponent.addRow() expects a FormGroup so create one (maybe the Kendo GridComponent can provide this?)
        let formFields = {}
        for (let colDef of this.GridColumnDefs) {
            formFields[colDef.displayName] = new UntypedFormControl();
        }
        let fg = new UntypedFormGroup(formFields)

        this.flagDirty();

        sender.addRow(fg);
    }

    public handleRemove(event: RemoveEvent): void {
        this.gridFormFieldComponent.FormInstanceElement.UserUpdatedData = true;
        this.kendoGridService.removeRow(event.dataItem['__gridRow'].databaseId).then(x => { });
    }

    // Certain field types when clicked shouldn't place the row they're in into edit mode (for example url builder button fields)
    private doNotEnterEditMode(columnIndex) {
        let colDef = this.GridColumnDefs[columnIndex];
        if (colDef) {
            let fieldLogic = this.fieldDefinitionService.getFieldClientLogicHandler(colDef.fieldDefinitionClassName);
            return (fieldLogic?.doNotTriggerGridRowEditFor());
        } else {
            return false;
        }
    }

    public handleEditCellClick(event: CellClickEvent) {
        if (this.doNotEnterEditMode(event.columnIndex - 1)) return;

        let isHTMLColumn: FormField;
        let handler: IFieldDefinitionLogic;
        this.DataUsedWithKendoGrid.CurrentDataItem = event.dataItem;

        // The first time a cell in a row is clicked get instructions for any flexible selection fields (hmm - should be checking if the call is needed)
        if (!this.rowEditInProgress) {
            this.flexibleSelectionFieldService.getFlexibleSelectFieldInstructionsForGridRow(this.formInstanceId, event.dataItem, this.GridColumnDefs, this.formInstance);
        }

        //VNEXT-894: KLW - Handle if the data is null and if not account if the form field is a URL
        if (event.columnIndex > 0) {
            if (event.dataItem.__gridRow) {
                if (event.dataItem.__gridRow.gridConfig) {
                    isHTMLColumn = event.dataItem.__gridRow.gridConfig.columnDefs[event.columnIndex - 1];

                    if (isHTMLColumn) {
                        handler = this.fieldDefinitionService.getFieldDefinition(isHTMLColumn.fieldDefinitionClassName).customLogicHandler;

                        if (handler.isURLLink())
                            return;
                    }
                }
            }
        }

        if (event.column instanceof CommandColumnComponent || event.rowIndex < 0) {
            // let handlers for specific command events (like edit, remove, update etc) handle these events
            return;
        } else {
            this.handleEdit({ sender: event.sender, rowIndex: event.rowIndex, columnIndex: event.columnIndex, dataItem: event.dataItem, isEdited: event.isEdited });
        }
    }

    // Handles when user starts editing a row
    public handleEdit({ sender, rowIndex, columnIndex, dataItem, isEdited }): void {
        //VNEXT-1540: KLW - Code to set resizable to true when editing
        this.resizable = true;

        this.kendoGridInstance = sender;

        let databaseId = dataItem.__gridRow.databaseId; // changed for VNEXT-1371 - used to be "dataItem.__gridRow.isPendingEditFor_DatabaseId;"

        this.extraHeightWhenEditingRow = 100;
        if (this.rowEditInProgress) {
            let editedRowIndex = this.dataUsedWithKendoGrid.editedRowIndex;
            // Kendo Grid fires its cellClick event when elements inside a Grid are clicked. That means clicking, for example, a dropdown or radio button fires the event
            // The next line handles that. May need to look to consume the click event in our own form field components (radio, dropdown etc.)
            if (editedRowIndex == rowIndex) return;

            if (this.gridIsDirty()) {
                // save the row that was being edited...
                let dataItemToSave = sender.data.data[editedRowIndex]; // sender.data is an object one of whose properties is data, the array of DataItems
                let cols = sender.columns['_results'];
                this.saving = true;
                this.kendoGridService.addOrUpdateRow(this.formInstanceElementId, this.formInstanceId, this.formFieldId, this.dataUsedWithKendoGrid.selectedGridRow, editedRowIndex, cols, dataItemToSave, false, this.gridFormFieldComponent.GridConfig, this.GridFormFieldComponent).then(x => {
                    this.closeAnyExistingRowEditor(sender, editedRowIndex);
                    this.saving = false;
                    if (rowIndex != editedRowIndex) {
                        this.setupRowForEditing(rowIndex, databaseId, sender);
                    }
                });
            } else {
                // close the row
                this.closeAnyExistingRowEditor(sender, editedRowIndex);
                this.setupRowForEditing(rowIndex, databaseId, sender);
            }
        } else {
            this.setupRowForEditing(rowIndex, databaseId, sender);
        }

        //VNEXT-1540: KLW - Code to set resizable to true when editing
        this.fitColumns();
    }

    //VNEXT-894: KLW - These methods are needed for validation on a new Kendo grid row
    public addValidationAndFlagForNewKendoRow() {
        this.createFormGroup();
        this.newRowIsBeingAdded = true;
    }

    public removeNewKendoRowFlag() {
        this.createFormGroup();
        this.newRowIsBeingAdded = false;
    }

    public get NewRowDisabled(): boolean {
        return this.newRowIsBeingAdded;
    }

    // Very rudimentary initial, quick stab at adjustment of the value of this.toolbarHeadersFilters to account for
    // wrapping grid column headers. Really, this should also take into account 1) the number of columns and
    // 2) the width of the component
    private adjustToolbarHeadersFiltersHeight() {
        let gridHeaderText = this.GridColumnDefs.map(x => x.displayName);
        let longestHeader = gridHeaderText.reduce(function (a, b) { return a.length > b.length ? a : b; }, '');
        if (longestHeader.length > 25) {
            this.toolbarHeadersFilters += longestHeader.length;
        }
    }

    private setupRowForEditing(rowIndex: number, databaseId: number, sender: any) {
        this.rowEditInProgress = true;
        this.dataUsedWithKendoGrid.editedRowIndex = rowIndex;

        //VNEXT-863, VNEXT-864 : KLW - There was an issue where CRUD operations on rows during virtualization were throwing errors. This was because
        //we were trying to get rows by their index in the Grid which works for the inital Grid data loaded, but when virtualization kicks in that data
        //is replaced by the 50 rows that show in the Grid. For example a row at index 500 will not be found in a Grid data set that has only 50 items if
        //we search by row index. The solution to this is to use the isPendingEditFor_DatabaseId value for each row where we can find it in whatever
        //Grid data set that has been loaded. If not we do a null check.
        let gridData = sender.data.data;
        let row = null;
        if (databaseId == 0) {
            row = gridData[rowIndex];
        } else {
            row = gridData.find(x => x.__gridRow.databaseId === databaseId);
        }

        if (row) {
            let gridRow: GridRowDef = row.__gridRow;

            gridRow.IsSelected = true;
            this.dataUsedWithKendoGrid.editedRowIndex = rowIndex;
            this.dataUsedWithKendoGrid.selectedGridRow = gridRow;
            this.dataUsedWithKendoGrid.backupForCancel();

            this.createFormGroup();

            sender.editRow(rowIndex, null);
        }
    }

    // Handles when user clicks "Add" or "Update" button on a row
    public handleAddOrUpdate({ sender, dataItem, rowIndex, formGroup, isNew }: SaveEvent): void {
        //VNEXT-1540: KLW - Code to set resizable to false
        this.resizable = false;

        let selectedGridRow = this.dataUsedWithKendoGrid.selectedGridRow;
        this.newRowIsBeingAdded = false;

        let cols = sender.columns['_results'];
        this.saving = true;
        this.kendoGridService.addOrUpdateRow(
            this.formInstanceElementId,
            this.formInstanceId,
            this.formFieldId,
            selectedGridRow,
            rowIndex,
            cols,
            dataItem,
            isNew,
            this.gridFormFieldComponent.GridConfig,
            this.GridFormFieldComponent,
            sender.data['total'] // added for VNEXT-1371 in order to set RowIndex for a new row
        ).then(x => {
            this.extraHeightWhenEditingRow = 0;
            this.closeAnyExistingRowEditor(sender, rowIndex);
            this.saving = false;
        });
    }

    public handleRowReorder(event: RowReorderEvent) {
        let originalIndexOfMovedRow = event.draggedRows[0].rowIndex;
        let movedRowId = event.draggedRows[0].dataItem.__gridRow.databaseId;
        let originalIndexOfTargetRow = event.dropTargetRow.rowIndex;
        let targetRowId = event.dropTargetRow.dataItem.__gridRow.databaseId;
        let position = event.dropPosition;
        this.flagDirty();
        this.kendoGridService.repositionRow(
            this.formInstanceElementId,
            originalIndexOfMovedRow,
            movedRowId,
            originalIndexOfTargetRow,
            targetRowId,
            position
        );
    }

    public handleCancel({ sender, rowIndex }: CancelEvent): void {
        //VNEXT-1540: KLW - Code to set resizable to false
        this.resizable = false;

        this.extraHeightWhenEditingRow = 0;
        this.flagClean();
        this.closeAnyExistingRowEditor(sender, rowIndex);
    }

    private setFormInstanceElementValue(wrapper: GridFormInstanceElementWrapper, backup: FormInstanceElement) {
        wrapper.formInstanceElement.textValue = backup.textValue;
        wrapper.formInstanceElement.intValue = backup.intValue;
        wrapper.formInstanceElement.decimalValue = backup.decimalValue;
        wrapper.formInstanceElement.doubleValue = backup.doubleValue;
        wrapper.formInstanceElement.booleanValue = backup.booleanValue;
    }

    // Sets that value of this.detailRowHeight which is used in calculating the grid height
    public handleDetailExpand(event: DetailExpandEvent) {
        // Timeout needed to allow time for the detail panel to be rendered and available
        setTimeout(() => {
            this.detailRowHeight += this.rowDetailPanel?.nativeElement.offsetHeight;
        }, 50);
    }

    public handleDetailCollapse(event: DetailCollapseEvent) {
        this.detailRowHeight = 0;
    }

    // Called from within <ng-template kendoGridCellTemplate> which allows the value to be set as innerHTML for HTML escaping
    public getCellDisplayValueForKendo(dataItem: any, columnDef: any, iColIndex: number): string {
        let value = dataItem[columnDef.name];
        return value;
    }

    // Really, this component shouldn't know about field-specific stuff - think about this factoring this out
    public allParamsSuppliedForUrlBuilder(dataItem: any, columnDef: any, iColIndex: number): boolean {
        let value = dataItem[columnDef.name];
        return value.indexOf('PARAM-IS-NOT-SET') == -1;
    }

    public openUrl(url: string) {
        window.open(url, '_blank').focus();
    }

    public handleTouched(formFieldComponent: FormFieldBaseComponent): void {
        let sourceFieldId: number = formFieldComponent.FormField.id;
        let sourceFormInstanceElement: FormInstanceElement = formFieldComponent.FormInstanceElement;

        if (this.formInstance != null) {
            // Let's make a copy of this form instance that we can manipulate freely (without causing side effects).
            let formInstanceCopy: FormInstance = new FormInstance();
            formInstanceCopy.id = this.formInstance.id;
            formInstanceCopy.form = this.formInstance.form;
            formInstanceCopy.formInstanceElements = [];
            if (this.formInstance.formInstanceElements != null) {
                for (let index: number = 0; index < this.formInstance.formInstanceElements.length; index++) {
                    let formInstanceElement: FormInstanceElement = this.formInstance.formInstanceElements[index];
                    let fieClone = formInstanceElement.clone();

                    if (fieClone.formFieldId == this.gridFormFieldComponent.FormField.id) {
                        fieClone.childGridRows = [];
                        let gridRow: GridRowViewModel = new GridRowViewModel();
                        gridRow.formFieldId = this.gridFormFieldComponent.FormField.id;
                        gridRow.cellDataHash = {};
                        let cellKeys = Object.keys(this.dataUsedWithKendoGrid.selectedGridRow.HshFormInstanceElementsByName);
                        for (let cellKeyIndex: number = 0; cellKeyIndex < cellKeys.length; cellKeyIndex++) {
                            let cellKey: string = cellKeys[cellKeyIndex];
                            let cellValue: GridFormInstanceElementWrapper = this.dataUsedWithKendoGrid.selectedGridRow.HshFormInstanceElementsByName[cellKey];
                            if (cellValue != null) {
                                let cellValueCopy: IFormContentElement = {
                                    id: cellValue.formInstanceElement.id,

                                    valueType: cellValue.formInstanceElement.valueType,

                                    textValue: cellValue.formInstanceElement.textValue,
                                    intValue: cellValue.formInstanceElement.intValue,
                                    decimalValue: cellValue.formInstanceElement.decimalValue,
                                    booleanValue: cellValue.formInstanceElement.booleanValue,
                                    doubleValue: cellValue.formInstanceElement.doubleValue,
                                    customValue: cellValue.formInstanceElement.customValue,

                                    childFormInstanceElements: []
                                };
                                if (cellKey == formFieldComponent.FormField.name) {
                                    cellValueCopy.textValue = sourceFormInstanceElement.textValue;
                                    cellValueCopy.intValue = sourceFormInstanceElement.intValue;
                                    cellValueCopy.decimalValue = sourceFormInstanceElement.decimalValue;
                                    cellValueCopy.booleanValue = sourceFormInstanceElement.booleanValue;
                                }
                                gridRow.cellDataHash[cellKey] = cellValueCopy;
                            }
                        }
                        fieClone.childGridRows.push(gridRow);
                    }

                    formInstanceCopy.formInstanceElements.push(fieClone);
                }
            }

            // Find my form instance element.
            let myFormField: FormField = this.gridFormFieldComponent.FormField;
            //this.fieldConditionalLogicService.sourceFieldValueChanged(formInstanceCopy, sourceFieldId).then(response => {
            this.fieldConditionalLogicService.sourceFieldValueChanged(formInstanceCopy, myFormField.id).then(response => {
                if ((response != null) && (response.targetFieldInstructions != null)) {
                    this.applyFieldConditionalLogicInstructions(response.targetFieldInstructions);
                } // if
            });
        }
    }

    //VNEXT-894: KLW - This is to manually open a URL in a new tab, otherwise using href will append the current environment url to the url being opened
    public openLink(url: string) {
        if (url != "")
            window.open(url, '_blank').focus();

        return false;
    }

    public getCellDisplayValue(dataItem: any, columnDef: any, iColIndex: number): string {
        let value: string = '';
        if (dataItem?.__gridRow) {
            dataItem = dataItem.__gridRow;
            if ((dataItem.FormInstanceElementWrappers != null) && (iColIndex < dataItem.FormInstanceElementWrappers.length))
                value = dataItem.FormInstanceElementWrappers[iColIndex].standinDisplayValue;
        } else {
            value = dataItem[columnDef.name];
        }

        return value;
    }

    // Not currently in use
    public RenderAsHtml(field: FormField) {
        let fieldDefClientLogic: IFieldDefinitionLogic = this.fieldDefinitionService.getFieldClientLogicHandler(field.fieldDefinitionClassName);
        return field.fieldDefinitionClassName.indexOf('Rich') > -1;
    }

    public ButtonLabel(columnDef: FormField): string {
        let label = columnDef.transientUrlBuilderButtonLabel?.trim();
        if (label) {
            return label;
        } else {
            return 'Open';
        }
    }

    public get KendoGridService(): KendoGridService {
        return this.kendoGridService;
    }

    public get DataUsedWithKendoGrid(): DataUsedWithKendoGrid {
        return this.dataUsedWithKendoGrid;
    }

    public get VirtualScrollingPageSize(): number {
        return this.dataUsedWithKendoGrid.gridState.take;
    }

    public get VirtualScrollingSkip(): number {
        let num = this.dataUsedWithKendoGrid.gridState.skip;
        return num;
    }

    public get Saving(): boolean {
        return this.saving;
    }

    public GridIsInvalid(): boolean {
        return this.gridValidity === FormFieldPropertyEnum.INVALID;
    }

    //VNEXT-980: KLW - Property to set the number of Kendo grid rows to display
    public GetGridHeight() {
        let rowCount = this.kendoGridService?.DataResult?.data?.length;
        let rowsDisplayed: number = this.gridFormFieldComponent.formField.GetDisplayKendoGridRows;

        let retVal = GridFieldEditorComponent.CalculateGridHeight(this.rowHeight,
            this.toolbarHeadersFilters,
            this.detailRowHeight,
            this.extraHeightWhenEditingRow,
            rowCount,
            rowsDisplayed,
            true, // Wrapped header text is enabled.
            this.ShowNumericTotalsFooter);

        return retVal;
    }

    public handleValidityChange(state: string) {
        //VNEXT-894: KLW - So the valid property for a FormGroup is not working correctly. It will not accurately represent if all controls are valid
        //or not. So the work around is to iterate through all controls and if one is invalid then return invalid, otherwise return valid.
        if (this.formGroup) {
            var isValid = FormFieldPropertyEnum.VALID;

            for (const field in this.formGroup.controls) { // 'field' is a string

                const control = this.formGroup.get(field); // 'control' is a FormControl  

                if (!control?.valid) {
                    isValid = FormFieldPropertyEnum.INVALID;
                    break;
                }
            }
            this.editorGridValidation.emit(isValid);
        }
    }

    public handleCellClose(event: any) {
        console.log('handleCellClose');
    }

    private closeAnyExistingRowEditor(grid: GridComponent, rowIndex = this.dataUsedWithKendoGrid.editedRowIndex): void {
        if (grid != null) {
            grid.closeRow(rowIndex);
        }
        this.dataUsedWithKendoGrid.editedRowIndex = undefined;
        this.dataUsedWithKendoGrid.CurrentDataItem = null;
        this.rowEditInProgress = false;
    }

    private flagDirty() {
        this.gridFormFieldComponent.FormInstanceElement.UserUpdatedData = true;
    }
    private flagClean() {
        this.gridFormFieldComponent.FormInstanceElement.UserUpdatedData = false;
    }
    private gridIsDirty() {
        return this.gridFormFieldComponent.FormInstanceElement.UserUpdatedData;
    }

    public get RowEditInProgress(): boolean {
        return this.rowEditInProgress;
    }

    public get FormFieldId(): number {
        return this.formFieldId;
    }

    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ END STUFF ADDED FOR KENDO GRID ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

    public get FieldDefinitionService() {
        return this.fieldDefinitionService;
    }

    public get GridFormFieldComponent() {
        return this.gridFormFieldComponent;
    }

    public get FormInstanceElementId() {
        return this.formInstanceElementId;
    }

    // Begin called by my HTML code.
    public ColumnIsSticky(colName: string): boolean {
        return this.gridFormFieldComponent.ColumnIsSticky(colName);
    }

    public GridColumnDisplayName(columnDef: FormField): string {
        let strDisplayName: string = columnDef.name;

        if ((columnDef !== undefined) && (columnDef !== null) && (columnDef.displayName !== null) && (columnDef.displayName.trim() !== ''))
            strDisplayName = columnDef.displayName;

        return strDisplayName;
    }
    // Commented out for VNEXT-1429 (remove old grid)
    //public getGridRowStyle(gridRow: GridRowDef): string {
    //    // If style has already been set for this gridRow, simply return it, otherwise calculate it
    //    // This is needed because this method is called from the template per cell, not per row
    //    // and we don't want to repeat the same calculation for every cell in a row
    //    if (gridRow.IsSelected && gridRow.RowSelectedStyle != null)
    //        return gridRow.RowSelectedStyle;
    //    else if (!gridRow.IsSelected && gridRow.RowUnselectedStyle != null)
    //        return gridRow.RowUnselectedStyle;

    //    let strStyle: string = '';
    //    if (gridRow.IsSelected) {
    //        if (this.gridFormFieldComponent.RuntimeData.iMaxComponentPreviewInstanceHeightRequired > 0) {
    //            strStyle = `height: ${this.gridFormFieldComponent.RuntimeData.iMaxComponentPreviewInstanceHeightRequired}px;`;
    //            strStyle += 'align-items: flex-start;'; // Needed to override a .mat-cell style.
    //        }
    //        gridRow.RowSelectedStyle = strStyle;
    //    } else {
    //        let rowsOfText = this.calulateRowsOfText(gridRow);

    //        if (rowsOfText > 2) {
    //            let lineHeight = 28;
    //            let rowHeight = Math.min((rowsOfText * lineHeight), 200);
    //            strStyle = `height: ${rowHeight}px;`;
    //            strStyle += 'align-items: flex-start;';
    //        } else {
    //            strStyle = `height: ${DEFAULT_UNSELECTED_ROW_HEIGHT}px`;
    //        }
    //        gridRow.RowUnselectedStyle = strStyle;
    //    }
    //    return strStyle;
    //}

    public getFormInstanceElementWrapper(hshColumnDef: FormField, gridRow: GridRowDef): GridFormInstanceElementWrapper[] {
        let wrapper: GridFormInstanceElementWrapper = gridRow.getFormInstanceElementWrapper(hshColumnDef);

        if (wrapper && wrapper.formInstanceElement) {
            if (!wrapper.formInstanceElement.transientValuesHash) {
                wrapper.formInstanceElement.transientValuesHash = {};
            }

            wrapper.formInstanceElement.transientValuesHash[GRID_ROW_ID_KEY] = gridRow.ClientId;
        } else if (!wrapper) {
            let errorMsg: string = "GridFormFieldComponent.getFormInstanceElementWrapper():  cannot get a form instance element wrapper.";
            super.raiseException(errorMsg);
        } else {
            let errorMsg: string = "GridFormFieldComponent.getFormInstanceElementWrapper():  cannot get a form instance element.";
            super.raiseException(errorMsg);
        }

        let arrWrapper: GridFormInstanceElementWrapper[] = [wrapper];

        return arrWrapper;
    }

    // Commented out for VNEXT-1429 (remove old grid)
    //public getValidationErrorsForCell(row: number, col: number): string[] {
    //    let cellName = this.getCellNameFor(row, col);
    //    let rowErrors: string[] = this.gridFormFieldComponent.RuntimeData.invalidGridRows[row];
    //    if (rowErrors) {
    //        this.editorGridValidation.emit(FormFieldPropertyEnum.INVALID);
    //        let currentFieldErrors = rowErrors[cellName];
    //        return currentFieldErrors ?? [];
    //    } else {
    //        this.editorGridValidation.emit(FormFieldPropertyEnum.VALID);
    //        return [];
    //    }
    //}

    //public FooterCellClass(colIndex: number): string {
    //    let cellClass: string = 'footer-cell';

    //    if (colIndex == 0)
    //        cellClass = 'first-footer-cell';

    //    return cellClass;
    //}

    // Commented out for VNEXT-1429 (remove old grid)
    //public getNumericTotalValue(iColIndex: number, colDef: FormField): string {
    //    // NOTE:  THIS METHOD RETURNS A BLANK FOR NON - NUMERIC COLUMNS BY DESIGN.
    //    let totalValue: string = '';

    //    if ((this.gridFormFieldComponent.RuntimeMetadata != null) && (this.gridFormFieldComponent.RuntimeMetadata.AllComponentsCount > 0)) {
    //        if ((iColIndex >= 0) && (iColIndex < this.gridFormFieldComponent.RuntimeMetadata.AllComponentsCount)) {
    //            let fieldDefLogic: IFieldDefinitionLogic = this.fieldDefinitionService.getFieldDefinition(colDef.fieldDefinitionClassName).customLogicHandler;

    //            if (fieldDefLogic.hasNumericData()) {
    //                let colTotal: number = this.gridFormFieldComponent.AllModesDataSource.getColumnTotal(colDef);

    //                if (colDef.roundResultToWholeNumber)
    //                    colTotal = Math.round(colTotal);
    //                if (colDef.showDigitsWithCommandSeparators)
    //                    totalValue = new Intl.NumberFormat('en-us', { minimumFractionDigits: 0 }).format(colTotal);
    //                else
    //                    totalValue = `${colTotal}`;
    //                if (colDef.showDollarSignPrefix || fieldDefLogic.hasDollarSignPrefix())
    //                    totalValue = `$ ${totalValue}`;
    //            }
    //        }
    //    }

    //    return (totalValue);
    //}

    // Commented out for VNEXT-1429 (remove old grid)
    //public get DeleteGridRowDisabled(): boolean {
    //    return this.gridFormFieldComponent.FormField.transientFixedFirstColumnValues != null;
    //}

    // Commented out for VNEXT-1429 (remove old grid)
    //public get IsLoadingGridData(): boolean {
    //    return this.gridFormFieldComponent.IsLoadingGridData;
    //}
    // Commented out for VNEXT-1429 (remove old grid)
    //public get LoadingDataText(): string {
    //    return this.gridFormFieldComponent.LoadingDataText;
    //}
    // Commented out for VNEXT-1429 (remove old grid)
    //public get LoadDataProgressMode(): ProgressBarMode {
    //    return ProgressBarConstants.BUFFER_MODE;
    //}
    // Commented out for VNEXT-1429 (remove old grid)
    //public get LoadDataProgressBufferValue(): number {
    //    return this.loadingDataProgress.iLoadingDataProgressBufferValue;
    //}
    // Commented out for VNEXT-1429 (remove old grid)
    //public get LoadDataProgressColor(): ThemePalette {
    //    return ProgressBarConstants.THEME_PALETTE_PRIMARY;
    //}
    // Commented out for VNEXT-1429 (remove old grid)
    //public get LoadDataProgressValue(): number {
    //    return this.loadingDataProgress.iLoadingDataProgressValue;
    //}
    // Commented out for VNEXT-1429 (remove old grid)
    //public get TotalRowCount(): number {
    //    return this.gridFormFieldComponent.TotalRowCount;
    //}
    // Commented out for VNEXT-1429 (remove old grid)
    //public get PageSize(): number {
    //    return this.loadingDataProgress.iPageSize;
    //}
    // Commented out for VNEXT-1429 (remove old grid)
    //public get PageSizeOptions(): number[] {
    //    return this.loadingDataProgress.arrPageSizeOptions;
    //}
    // Commented out for VNEXT-1429 (remove old grid)
    //public get ShowPaginator(): boolean {
    //    let show: boolean = this.gridFormFieldComponent.TotalRowCount > this.loadingDataProgress.arrPageSizeOptions[0];
    //    return show;
    //}
    // Commented out for VNEXT-1429 (remove old grid)
    //public get PaginatorDisabled(): boolean {
    //    return this.loadingDataProgress.isLoadingGridData && (this.loadingDataProgress.iLoadingDataProgressValue != 100);
    //}
    // Commented out for VNEXT-1429 (remove old grid)
    //public get ShowFirstLastButtons(): boolean {
    //    return true;
    //}
    // Commented out for VNEXT-1429 (remove old grid)
    //public get GridColumnNamesWithActions(): string[] {
    //    return this.gridFormFieldComponent.GridColumnNamesWithActions;
    //}
    // Commented out for VNEXT-1429 (remove old grid)
    //public get HeaderRowIsSticky(): boolean {
    //    return true;
    //}
    // Commented out for VNEXT-1429 (remove old grid)
    //public get ExpandedDetailColSpan(): number {
    //    return this.gridFormFieldComponent.GridColumnNamesWithActions.length;
    //}
    //public elementIsExpanded(gridRow: GridRowDef): boolean {
    //    return gridRow.IsExpanded;
    //}
    // Commented out for VNEXT-1429 (remove old grid)
    //public elementIsSelected = (i: number, gridRow: GridRowDef): boolean => {
    //    return true;
    //}
    // Commented out for VNEXT-1429 (remove old grid)
    //public expandedDetailRowClass(gridRow: GridRowDef): string {
    //    return gridRow.IsExpanded ? 'displayed-expanded-grid-row' : 'hidden-expanded-grid-row';
    //}
    // Commented out for VNEXT-1429 (remove old grid)
    //public expandedDetailRowStyle(gridRow: GridRowDef): string {
    //    return gridRow.IsExpanded ? '' : 'display: none;';
    //}
    // Commented out for VNEXT-1429 (remove old grid)
    //public expandGridRow(gridRow: any): void {
    //    // TO DO:  CODE THIS METHOD.
    //}
    // Commented out for VNEXT-1429 (remove old grid)
    //public get SiteIsInAlphaMode(): boolean {
    //    return this.currentUserService.user.isSystemAdmin && this.currentSiteService.Site.betaFeaturesEnabled;
    //}

    public get SiteIsInBetaMode(): boolean {
        return this.currentSiteService.Site.betaFeaturesEnabled;
    }
    // Commented out for VNEXT-1429 (remove old grid)
    //public toggleExpandGridRow(gridRow: GridRowDef): void {
    //    gridRow.IsExpanded = !gridRow.IsExpanded;
    //}
    // End called by my HTML code.

    // Commented out for VNEXT-1429 (remove old grid)
    // Handle control events.
    //public userTriggeredColumnSort(eventData: any): void {
    //    this.gridFormFieldComponent.userTriggeredColumnSort(eventData);
    //}

    // Commented out for VNEXT-1429 (remove old grid)
    //public unselectedGridRowClicked(clickedGridRow: GridRowDef, clickedCellWrapper: GridFormInstanceElementWrapper): void {
    //    if (this.rowHasValidationErrors(this.gridFormFieldComponent.RuntimeData.selectedGridRowIndex)) {
    //        alert("You have invalid data in this row. Please correct before moving to another row. (2)");
    //        return;
    //    }
    //    //VNEXT-894: KLW - Account if the form field is a URL or not
    //    let handler: IFieldDefinitionLogic = this.fieldDefinitionService.getFieldDefinition(clickedCellWrapper.fieldClass).customLogicHandler;

    //    if (handler) {
    //        if (handler.isURLLink())
    //            return;
    //    }


    //    // Make sure we have indexed the DynamicComponentHost directives by name.
    //    if (this.gridFormFieldComponent.RuntimeData.hshDirectivesByName == null)
    //        this.dynamicComponentHostsUpdated();

    //    // If an existing row is selected, unselect it now.
    //    this.unselectSelectedRowIfAny();

    //    // Select the new row.
    //    this.gridFormFieldComponent.RuntimeData.selectedGridRowIndex = clickedGridRow.RowIndex;
    //    clickedGridRow.IsSelected = true;

    //    // Create any virtual col defs.
    //    // Show controls within the newly selected row.
    //    this.createFieldControlsInRow(clickedGridRow, clickedCellWrapper.colIndex);
    //}

    // Commented out for VNEXT-1429 (remove old grid)
    //public DynamicComponentHostNameFor(gridRow: GridRowDef, hshColumnDef: FormField, columnIndex: number): string {
    //    // From the previously inline HTML:

    //    let name = this.getCellNameFor(gridRow.RowIndex, hshColumnDef.fieldOrder);

    //    return name;
    //}

    // Commented out for VNEXT-1429 (remove old grid)
    //public removeGridRowClicked(gridRow: GridRowDef): void {
    //    let iIndexOfRowToDelete: number = gridRow.RowIndex;

    //    let bDeleted: boolean = this.gridFormFieldComponent.AllModesDataSource.removeRow(gridRow.ClientId);

    //    if (bDeleted && (this.gridFormFieldComponent.RuntimeData.selectedGridRowIndex >= 0)) {
    //        if (iIndexOfRowToDelete < this.gridFormFieldComponent.RuntimeData.selectedGridRowIndex) {
    //            this.gridFormFieldComponent.RuntimeData.selectedGridRowIndex--;
    //        } else if (iIndexOfRowToDelete == this.gridFormFieldComponent.RuntimeData.selectedGridRowIndex) {
    //            this.gridFormFieldComponent.RuntimeData.selectedGridRowIndex = -1;
    //        }
    //    }
    //    this.gridFormFieldComponent.designChange.emit();

    //    this.gridFormFieldComponent.FormInstanceElement.UserUpdatedData = true;

    //    return;
    //}

    // Note:  method controlValueChanged(), next, must be defined as an arrow function.
    public gridColumnValueChanged(columnDef: FormField, value: FormInstanceElement): void {

        if (value != null) {
            this.flagDirty();

            let totalsHelper: GridColumnTotalsHelper = <GridColumnTotalsHelper>this.helper.DataSpy;
            if (totalsHelper != null)
                totalsHelper.cellValueChanged(this.dataUsedWithKendoGrid.editedRowIndex, columnDef, value);
        }
    }

    // Commented out for VNEXT-1429 (remove old grid)
    //public controlValueChanged = (value: FormInstanceElement, columnDefParam: FormField = null) => {
    //    console.log('controlValueChanged 1');
    //    if (value.transientValuesHash) {
    //        console.log('controlValueChanged 2');
    //        let iGridRowId: number = value.transientValuesHash[GRID_ROW_ID_KEY];
    //        let iColumnId: number = value.transientValuesHash[GRID_COLUMN_ID_KEY];

    //        if ((iGridRowId != null) && (columnDefParam != null || iColumnId != null)) {
    //            let columnDef: FormField = columnDefParam != null ? columnDefParam : this.gridFormFieldComponent.GridConfig.getColumnDefByClientId(iColumnId);
    //            let gridRowDef: GridRowDef = this.gridFormFieldComponent.AllModesDataSource.getRowByClientId(iGridRowId);

    //            if ((columnDef != null) && (gridRowDef != null)) {
    //                let wrapper: GridFormInstanceElementWrapper = gridRowDef.getFormInstanceElementWrapper(columnDef);

    //                if (wrapper) {
    //                    // A value has been set, so set the 'transientValueSetFlag' flag.
    //                    wrapper.formInstanceElement.UserUpdatedData = true;
    //                    // pharvey - let the Grid's FormInstanceElement know there's been a value change
    //                    this.gridFormFieldComponent.FormInstanceElement.UserUpdatedData = true;

    //                    // Perform model value changed logic.
    //                    this.handleNgModelChangeLogic(gridRowDef, columnDef, wrapper, FormFieldProcessingPhaseEnum.EDITING_DATA);
    //                }
    //            }

    //        }
    //    }

    //    return;
    //}

    // Commented out for VNEXT-1429 (remove old grid)
    // Note:  method controlReceivedFocus(), next, must be defined as an arrow function.
    //public controlReceivedFocus = (myComponent: IFormFieldComponent, formFieldComponent: IFormFieldComponent, event: FocusEvent) => {
    //    // Note:  the following line should not be needed, but this arrow function
    //    //        is not returning property 'this' as does arrow function controlValueChanged().
    //    let myself: GridFormFieldComponent = <GridFormFieldComponent>myComponent;

    //    let componentFormField: FormField = formFieldComponent.FormField;

    //    // Find the selected cell index.
    //    let cellIndex: number = 0;
    //    let colDefs: FormField[] = myself.GridConfig.ColumnDefs;
    //    for (let colIndex: number = 0; colIndex < colDefs.length; colIndex++) {
    //        let colDef: FormField = colDefs[colIndex];
    //        if (colDef.name == componentFormField.name) {
    //            cellIndex = colIndex;
    //            break;
    //        }
    //    }
    //    this.gridFormFieldComponent.RuntimeData.selectedGridColumnIndex = cellIndex;
    //}

    // Commented out for VNEXT-1429 (remove old grid)
    // pjh - 01/28/2022 - added for new "cancel" action on a grid row
    //public unSelectRow(clickedGridRow: GridRowDef): void {
    //    // Make sure we have indexed the DynamicComponentHost directives by name.
    //    if (this.gridFormFieldComponent.RuntimeData.hshDirectivesByName == null)
    //        this.dynamicComponentHostsUpdated();

    //    // this does not indicate that the row is valid, but simply that we're not
    //    // going to track it for now since the user is canceling their edit
    //    delete this.gridFormFieldComponent.RuntimeData.invalidGridRows[clickedGridRow.RowIndex];

    //    this.removeFieldControlsFromGridRow(clickedGridRow);
    //    clickedGridRow.IsSelected = false;
    //    this.gridFormFieldComponent.RuntimeData.selectedGridRowIndex = -1;
    //}

    // Commented out for VNEXT-1429 (remove old grid)
    // Handle MatPaginator events.
    //public handlePageEvent(eventData: PageEvent): void {
    //    this.gridFormFieldComponent.handlePageEvent(eventData);
    //}

    public getCellNameFor(row: number, col: number): string {
        return `t-${row}-${col}`;
    }
    // End handling MatPaginator events.

    // Helper methods.
    // Commented out for VNEXT-1429 (remove old grid)
    //private unselectSelectedRowIfAny(): void {
    //    // If an existing row is selected, unselect it now.
    //    if (this.gridFormFieldComponent.RuntimeData.selectedGridRowIndex >= 0) {
    //        let selectedGridRow: GridRowDef = this.gridFormFieldComponent.AllModesDataSource.getGridRow(this.gridFormFieldComponent.RuntimeData.selectedGridRowIndex);

    //        if (selectedGridRow == null) {
    //            let errorMsg: string = `GridFormFieldComponent.unselectedGridRowClicked():  could not un-select ` + `grid row ${this.gridFormFieldComponent.RuntimeData.selectedGridRowIndex} as that index does not exist.`;
    //            this.raiseException(errorMsg);
    //        }

    //        // Remove any controls from the previously selected row.
    //        this.removeFieldControlsFromGridRow(selectedGridRow);
    //        selectedGridRow.IsSelected = false;
    //    }
    //}

    //private rowHasValidationErrors(row: number) {
    //    return this.gridFormFieldComponent.RuntimeData.invalidGridRows[row] != null
    //}

    // Commented out for VNEXT-1429 (remove old grid)
    //private dynamicComponentHostsUpdated(): void {
    //    if (this.dynamicComponentHosts) {
    //        this.gridFormFieldComponent.RuntimeData.templateDirectives = this.dynamicComponentHosts.toArray();

    //        this.gridFormFieldComponent.RuntimeData.hshDirectivesByName = {};

    //        for (let iDirective: number = 0; iDirective < this.gridFormFieldComponent.RuntimeData.templateDirectives.length; iDirective++) {
    //            let directive: DynamicComponentHostDirective = this.gridFormFieldComponent.RuntimeData.templateDirectives[iDirective];

    //            this.gridFormFieldComponent.RuntimeData.hshDirectivesByName[directive.name] = directive;
    //        }
    //    }
    //}

    // Commented out for VNEXT-1429 (remove old grid)
    //private createFieldControlsInRow(gridRow: GridRowDef, iClickedColumn: number): void {
    //    // Find the ng-template instances associated with this row.
    //    let iRowIndex: number = gridRow.RowIndex;
    //    let columnDefs: FormField[] = this.gridFormFieldComponent.GridConfig.getRuntimeColumnDefsWithConfiguredVirtualFormFields(this.fieldDefinitionService);
    //    let iColCount: number = columnDefs.length;

    //    let iTemplateOffset: number = (iRowIndex * iColCount);
    //    let iNumTemplatesRequired: number = iTemplateOffset + iColCount;

    //    if (this.dynamicComponentHosts != null) {
    //        if (this.dynamicComponentHosts.length >= iNumTemplatesRequired) {
    //            // Reset the number of row form fields created.
    //            this.gridFormFieldComponent.RuntimeData.iNumRowFormFieldsInitialized = 0;
    //            // Reset the hash of dynamically created formFields
    //            this.gridFormFieldComponent.RuntimeData.dynamicallyCreatedFormFieldsByName = {};

    //            // Create one form field component per column.
    //            let iCol: number = 0;

    //            for (let iCol: number = 0; iCol < iColCount; iCol++) {
    //                let columnDef: FormField = columnDefs[iCol];

    //                // Note:  we should not assume that the array of DynamicComponentHosts
    //                //        directives will be in row, column order(in fact, they appear
    //                //        to be in column, row order)

    //                // this is referring to this line in the HTML...
    //                // <ng-template dynamic-component-host name="t-{{gridRow.RowIndex}}-{{hshColumnDef.fieldOrder}}"></ng-template>
    //                // ... where the dynamic-component-host directive is identifying the template which needs to display the control(s) for a field
    //                let directive: DynamicComponentHostDirective = this.getDirective(iRowIndex, iCol);

    //                let cellFormInstanceElementWrapper: GridFormInstanceElementWrapper = gridRow.FormInstanceElementWrappers[iCol]; // rowCellFormInstanceElementWrappers[iCol];
    //                let focusOnThisField = iCol === iClickedColumn;
    //                this.createFieldControlInRowCell(columnDef, cellFormInstanceElementWrapper, directive.viewContainerRef, focusOnThisField, iRowIndex, iCol);
    //            } // for
    //        } // if
    //    } // if

    //    return;
    //}

    // Commented out for VNEXT-1429 (remove old grid)
    //private getDirective(iRowIndex: number, iColIndex: number): DynamicComponentHostDirective {
    //    if ((this.dynamicComponentHosts != null) && (this.gridFormFieldComponent.RuntimeData.hshDirectivesByName != null)) {
    //        let hostsLength: number = this.dynamicComponentHosts.toArray().length;
    //        let directivesHashLength: number = Object.keys(this.gridFormFieldComponent.RuntimeData.hshDirectivesByName).length;

    //        if (hostsLength != directivesHashLength)
    //            this.dynamicComponentHostsUpdated();
    //    }

    //    let strDirectiveName = this.getCellNameFor(iRowIndex, iColIndex);

    //    let directive: DynamicComponentHostDirective = this.gridFormFieldComponent.RuntimeData.hshDirectivesByName[strDirectiveName];

    //    if (directive == null) {
    //        let error = `GridFormFieldComponent.getDirective():  cannot get directive ${strDirectiveName}.`;
    //        throw error;
    //    }

    //    return (directive);
    //}

    // Commented out for VNEXT-1429 (remove old grid)
    // pharv - 4/12/2022 - modified to take row and col indexes in order to keep
    // dynamically generated FormField components in memory so they can be looked up
    // by name and used to render validation messages
    //private createFieldControlInRowCell(formField: FormField, cellFormInstanceElementWrapper: GridFormInstanceElementWrapper, viewContainerRef: ViewContainerRef, bSetFocusInFieldControl: boolean, rowIndex: number, colIndex: number): void {
    //    let fieldTypeAndName: FieldTypeAndName = this.formFieldTypeAndNameService.getFieldTypeAndField(formField.fieldDefinitionClassName);
    //    let formFieldClass: AngularCoreType<any> = fieldTypeAndName.formFieldClass;
    //    let componentRef: ComponentRef<FormFieldBaseComponent> =
    //        fieldTypeAndName.componentRepresentative.createFormFieldDynamically(this.resolver, this.fieldDefinitionService, viewContainerRef, formField, this.gridFormFieldComponent.Mode, cellFormInstanceElementWrapper.formInstanceElement, false, null, true);

    //    cellFormInstanceElementWrapper.componentRef = componentRef;
    //    cellFormInstanceElementWrapper.formInstanceElement.transientValuesHash[GRID_COLUMN_ID_KEY] = formField.gridColClientId;

    //    let formFieldComponent: any = componentRef.instance;
    //    let cellName = this.getCellNameFor(rowIndex, colIndex);
    //    this.gridFormFieldComponent.RuntimeData.dynamicallyCreatedFormFieldsByName[cellName] = formFieldComponent;

    //    // https://stackoverflow.com/a/49038739
    //    let formFieldComp = componentRef.instance as FormFieldBaseComponent;
    //    formFieldComp.touched.subscribe(() => {
    //        this.updateValidationErrors(rowIndex, colIndex);
    //    });

    //    formFieldComponent.onInit.subscribe(hshEventData => {
    //        let component: FormFieldBaseComponent = hshEventData['component'];
    //        if (component) {
    //            let field: FormField = component.FormField;

    //            this.gridColumnInit(hshEventData, field);
    //        }
    //    });

    //    componentRef.instance.registerOnChange(this.controlValueChanged);
    //    componentRef.instance.registerOnFocus(this.gridFormFieldComponent, this.controlReceivedFocus);

    //    if (bSetFocusInFieldControl) {
    //        let formFieldBaseComponent: FormFieldBaseComponent = <FormFieldBaseComponent>formFieldComponent;

    //        formFieldBaseComponent.setFocus();
    //    }
    //}

    // Commented out for VNEXT-1429 (remove old grid)
    //public removeFieldControlsFromGridRow(gridRow: GridRowDef): void {
    //    // Find the ng-template instances associated with this row.
    //    let iRowIndex: number = gridRow.RowIndex;
    //    let columnDefs: FormField[] = this.gridFormFieldComponent.GridConfig.ColumnDefs;
    //    let iColCount: number = this.gridFormFieldComponent.GridConfig.ColumnCount;

    //    let iTemplateOffset: number = (iRowIndex * iColCount);
    //    let iNumTemplatesRequired: number = iTemplateOffset + iColCount;

    //    // Clear any content from the cell templates.
    //    if (this.dynamicComponentHosts != null) {
    //        if (this.dynamicComponentHosts.length >= iNumTemplatesRequired) {
    //            let templateDirectives: DynamicComponentHostDirective[] = this.dynamicComponentHosts.toArray();

    //            let rowCellFormInstanceElementWrappers: GridFormInstanceElementWrapper[] = gridRow.FormInstanceElementWrappers;

    //            for (let iCol: number = 0; iCol < iColCount; iCol++) {
    //                // Tell the form field to save its data.
    //                let columnDef: FormField = columnDefs[iCol];
    //                let cellFormInstanceElementWrapper: GridFormInstanceElementWrapper = rowCellFormInstanceElementWrappers[iCol];

    //                if (cellFormInstanceElementWrapper.componentRef != null) {
    //                    let hshIgnored: any = {}; // TO DO:  GET THE GRID'S FORM INSTANCE AND PASS IT TO METhod saveData().
    //                    cellFormInstanceElementWrapper.componentRef.instance.saveData(hshIgnored);

    //                    let fieldDefinition: FieldDefinition = this.fieldDefinitionService.getFieldDefinition(columnDef.fieldDefinitionClassName);
    //                    let fieldLogicHandler: IFieldDefinitionLogic = fieldDefinition.customLogicHandler;
    //                    cellFormInstanceElementWrapper.standinDisplayValue = fieldLogicHandler.getDisplayValue(columnDef, cellFormInstanceElementWrapper.formInstanceElement, gridRow, FormFieldProcessingPhaseEnum.EDITING_DATA);
    //                }

    //                // Note:  we should not assume that the array of DynamicComponentHosts
    //                //        directives will be in row, column order(in fact, they appear
    //                //        to be in column, row order)
    //                let directive: DynamicComponentHostDirective = this.getDirective(iRowIndex, iCol);
    //                const viewContainerRef = directive.viewContainerRef;

    //                // Clear any content.
    //                viewContainerRef.clear();

    //                // Nullify the prior component reference.
    //                cellFormInstanceElementWrapper.componentRef = null;
    //            } // for
    //        } // if
    //    } // if

    //    // Done.
    //    return;
    //}

    // Commented out for VNEXT-1429 (remove old grid)
    // Adds/Removes validation error messages to/from the invalidGridRows property
    //private updateValidationErrors(row: number, col: number) {
    //    let cellName = this.getCellNameFor(row, col);
    //    let field: IFormFieldComponent = this.gridFormFieldComponent.RuntimeData.dynamicallyCreatedFormFieldsByName[cellName];
    //    let currentFieldErrors = field.getValidationErrors(true);
    //    let selectedGridRow: GridRowDef = this.gridFormFieldComponent.AllModesDataSource.getGridRow(this.gridFormFieldComponent.RuntimeData.selectedGridRowIndex);

    //    let rowErrors: string[] = this.gridFormFieldComponent.RuntimeData.invalidGridRows[row];
    //    if (currentFieldErrors.length > 0) {
    //        if (rowErrors == null) {
    //            rowErrors = [];
    //        }
    //        rowErrors[cellName] = currentFieldErrors;
    //        this.gridFormFieldComponent.RuntimeData.invalidGridRows[row] = rowErrors;
    //        selectedGridRow.IsInvalid = true;
    //    } else {
    //        // Delete validation errors that no longer apply
    //        if (rowErrors != null) {
    //            if (rowErrors.hasOwnProperty(cellName)) {
    //                delete rowErrors[cellName];
    //            }
    //        }
    //        if (this.gridFormFieldComponent.RuntimeData.invalidGridRows.hasOwnProperty(row) && Object.keys(this.gridFormFieldComponent.RuntimeData.invalidGridRows[row]).length == 0) {
    //            delete this.gridFormFieldComponent.RuntimeData.invalidGridRows[row];
    //        }
    //        selectedGridRow.IsInvalid = false;
    //    }

    //    // Was getting ExpressionChangedAfterItHasBeenCheckedError
    //    //so bump this binding update to the next change detection loop
    //    UtilityHelper.runWhenStackClear(() => {
    //        this.gridFormFieldComponent.FieldHasValidationError = Object.keys(this.gridFormFieldComponent.RuntimeData.invalidGridRows).length > 0; // note: the field being marked imvalid here is the grid as a whole
    //    });
    //    return currentFieldErrors;
    //}

    // Commented out for VNEXT-1429 (remove old grid)
    //private calulateRowsOfText(gridRow: GridRowDef) {
    //    let rowsOfText: number = 0;
    //    for (let el of gridRow.FormInstanceElementWrappers) {
    //        let rows = this.rowsOfText(gridRow.getTotalColumnCount(), el);
    //        if (rows > rowsOfText) {
    //            rowsOfText = rows;
    //        }
    //    }
    //    rowsOfText = Math.max(this.gridFormFieldComponent.RuntimeMetadata.MaxRowsOfTextToDisplayOnUnselectedGridRows, rowsOfText);
    //    return rowsOfText;
    //}

    // Commented out for VNEXT-1429 (remove old grid)
    // pharv - 01/28/2022 - the goal here is calculate an estimate of how many rows of text
    // a cell contains. The calculation takes into account current column width.
    //private rowsOfText(columnCount: number, el: GridFormInstanceElementWrapper): number {
    //    let characterPixelHeight = 32;
    //    let avgCharacterPixelWidth = 7;
    //    let colWidth = this.gridFormFieldComponent.RuntimeData.gridWidth / columnCount;
    //    let lengthOfTextInPixels = characterPixelHeight;
    //    if (el && el.standinDisplayValue) lengthOfTextInPixels = el.standinDisplayValue.length * avgCharacterPixelWidth;
    //    let rowsOfText = lengthOfTextInPixels / colWidth;
    //    return rowsOfText;
    //}

    private jobCompletedOrUpdatedArrowFunction = (asyncJob: AsyncJob): void => {
        // TO DO:  IMPLEMENT THIS METHOD.
    }

    private hideGridBodyIfSoConfigured(): void {
        let gridElement = $(this).find('kendo-grid');
        if (gridElement != null) {
            let gridBodyElement = $(gridElement).find("[aria-label='Data table']");
            if (gridBodyElement != null) {
                gridBodyElement.remove();
            }
        }
    }

    //VNEXT-1540: KLW - Code to auto resize the columns when editing
    //https://www.telerik.com/kendo-angular-ui/components/grid/columns/resizing#auto-fitting-content
    //For this to work, you MUST have the grid attribute of "resizable" (https://www.telerik.com/kendo-angular-ui/components/grid/api/gridcomponent#resizable) set to true
    private fitColumns(): void {
        // this.ngZone.onStable
        //     .asObservable()
        //     .pipe(take(1))
        //     .subscribe(() => {
        //         this.kendoGridInstance.autoFitColumns();
        //     });
    }
}
