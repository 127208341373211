<!--TEAMS-424: KLW - For all instance of using buttonConfig check if not null with ?, otherwise errors will occur upon loading-->
<mat-toolbar class="tools noprint">
    <div class="left">
        <h1 *ngIf="! this.Perspective">{{title}}</h1>

        <ng-container *ngIf="this.Perspective">
            <ng-container *ngIf="this.PerspectiveLabel && (this.PerspectiveLabel.trim() !== '')">
                <span class="perspective-label">{{this.PerspectiveLabel.trim()}}:</span>
            </ng-container>

            <mat-select class="perspective-mat-select"
                        [(ngModel)]="this.PerspectiveName"
                        (ngModelChange)="this.perspectiveNameChanged()">
                <mat-option *ngFor="let perspectiveName of this.PerspectiveNames"
                            [value]="perspectiveName">
                    {{perspectiveName}}
                </mat-option>
            </mat-select>
        </ng-container>

        <!--(click)="emitButtonClick($event, btn.value)"-->
        <button *ngFor="let btn of buttonConfig?.left"
                mat-button
                disabled="{{btn.disabled === true}}"
                (click)="emitButtonClick($event, btn)"
                name="{{btn.value}}"
                id="listViewLeftIcons">
            <span *ngIf="btn.icon == null">{{btn.label}}</span>
            <mat-icon *ngIf="(btn.icon != null) && (btn.iconFormat == 'MatIcon')" title="{{btn.label}}">{{btn.icon}}</mat-icon>
            <mat-icon *ngIf="(btn.icon != null) && (btn.iconFormat == 'SvgIcon')" svgIcon="{{btn.icon}}" class="default-svg-styling" title="{{btn.label}}"></mat-icon>
        </button>

        <form *ngIf="DisplaySearch" [formGroup]="this.FilterTermFormGroup" style="display:inline">
            <input matInput formControlName="filterTerm" placeholder="Search" autocomplete="off" class="search" />
        </form>
    </div>

    <div class="right"
         id="listViewRightIcons">
        <!--(click)="emitButtonClick($event, btn.value)">-->
        <!-- TEAMS-653: KLW - Attribute binding for ngClass to set the value of cssClass of a ButtonConfig -->
        <button *ngFor="let btn of buttonConfig?.right"
                [ngClass]="btn.cssClass"
                mat-button class="toolbar-button-right"
                disabled="{{btn.disabled === true}}"
                (click)="emitButtonClick($event, btn)"
                name="{{btn.value}}">
            <span *ngIf="btn.icon == null" class="button-without-icon">{{btn.label}}</span>
            <ng-container *ngIf="btn.icon != null">
                <mat-icon *ngIf="IsMaterialIconWithDefaultStyle(btn)" title="{{btn.label}}">{{btn.icon}}</mat-icon>
                <mat-icon *ngIf="IsMaterialIconWithCustomStyle(btn)" class="{{btn.iconDisplayStyle}}" title="{{btn.label}}">{{btn.icon}}</mat-icon>
                <mat-icon *ngIf="IsMaterialIconWithInlineStyle(btn)" style="{{btn.inlineStyles}}" title="{{btn.label}}">{{btn.icon}}</mat-icon>

                <mat-icon *ngIf="IsSvgIconWithNoInlineStyles(btn)" svgIcon="{{btn.icon}}" class="default-svg-styling" title="{{btn.label}}"></mat-icon>
                <mat-icon *ngIf="IsSvgIconWithInlineStyles(btn)" svgIcon="{{btn.icon}}" class="default-svg-styling" title="{{btn.label}}" style="{{btn.inlineStyles}}"></mat-icon>
            </ng-container>
        </button>

        <ng-container *ngIf="this.HasTrashCan">
            <!--<mat-icon svgIcon="vertical-line" class="left-vertical-divider-mat-icon"></mat-icon>-->

            <div class="drop-area center-icon" [ngClass]="this.ItemIsBeingDragged ? 'enabled-trash-can-mat-icon' : 'disabled-trash-can-mat-icon'"
                 droppable
                 [dropScope]="['item']"
                 (onDrop)="this.onItemDroppedOnTrash($event)">
                <!-- <mat-icon svgIcon="trash" class="toolbar-button-right"></mat-icon> -->
                <i [appIcon] class="toolbar-button-right" svgIcon="trash" appIconItemType="{{this.iconType}}"></i>
            </div>

            <!--<mat-icon svgIcon="vertical-line" class="right-vertical-divider-mat-icon"></mat-icon>-->
        </ng-container>

        <!-- 08-05-2022 SWH note:  added the following, optional 'navigation' buttons. -->
        <!--(click)="emitButtonClick($event, btn.value)"-->
        <button *ngFor="let btn of buttonConfig?.navigation"
                mat-button class="toolbar-button-right"
                disabled="{{btn.disabled === true}}"
                (click)="emitButtonClick($event, btn)">
            <span *ngIf="btn.icon == null">{{btn.label}}</span>
            <ng-container *ngIf="btn.icon != null">
                <mat-icon *ngIf="(btn.icon != null) && (btn.iconFormat == 'MatIcon') && (btn.iconDisplayStyle == 'Default')" title="{{btn.label}}">{{btn.icon}}</mat-icon>
                <mat-icon *ngIf="(btn.icon != null) && (btn.iconFormat == 'MatIcon') && (btn.iconDisplayStyle != 'Default')" class="{{btn.iconDisplayStyle}}" title="{{btn.label}}">{{btn.icon}}</mat-icon>
                <mat-icon *ngIf="(btn.icon != null) && (btn.iconFormat == 'SvgIcon')" svgIcon="{{btn.icon}}" class="default-svg-styling" title="{{btn.label}}"></mat-icon>
            </ng-container>
        </button>

        <!-- End 08-05-2022 change. -->

        <button mat-button [matMenuTriggerFor]="sortMenu" *ngIf="this.View==='tile'">
            <mat-icon>sort_by_alpha</mat-icon>
        </button>

        <button mat-button [matMenuTriggerFor]="viewsMenu" title="View Mode Menu" *ngIf="viewOverride == null">
            <mat-icon *ngIf="view==='tile'" class="view-menu-mode-icon">view_module</mat-icon>
            <mat-icon *ngIf="view==='grid'" class="view-menu-mode-icon">view_list</mat-icon>
        </button>
    </div>
</mat-toolbar>

<div id="listViewMenuBar">
    <mat-menu #viewsMenu="matMenu">
        <button mat-menu-item (click)="setView('tile')" [ngClass]="{selectedMenu: this.View==='tile'}"><mat-icon>view_module</mat-icon>Tile</button>
        <button mat-menu-item (click)="setView('grid')" [ngClass]="{selectedMenu: this.View==='grid'}"><mat-icon>view_list</mat-icon>Grid</button>
    </mat-menu>
    <mat-menu #sortMenu="matMenu">
        <button mat-menu-item (click)="setSortDirection('asc')" [ngClass]="{selectedMenu: sortDirection==='desc'}"><mat-icon>arrow_upward</mat-icon>A → Z</button>
        <button mat-menu-item (click)="setSortDirection('desc')" [ngClass]="{selectedMenu: sortDirection==='asc'}"><mat-icon>arrow_downward</mat-icon>Z → A</button>
    </mat-menu>
</div>

<div class="list-view-content"
     id="listViewContent">
    <app-tile-view *ngIf="this.View==='tile'"
                   [list]="this.ListItems"
                   [listType]="listType"
                   [listItemsAreDraggable]="listItemsAreDraggable"
                   [defaultSortColumn]="defaultSortColumn"
                   [filterTerm]="this.FilterTerm"
                   [selectedItemId]="selectedId"
                   [itemActionButtons]="buttonConfig?.item_actions"
                   [parentTitle]="title"
                   [hasCheckboxes]="this.hasCheckboxes"
                   [hideActionButtons]="this.hideActionButtons"
                   [typeSortOrderConfig]="typeSortOrderConfig"
                   [sortDirection]="GetSortDirection"
                   [currentFolder]="this.currentFolder"
                   (editClick)="editClick.emit($event)"
                   (itemActionClick)="itemActionClick.emit($event)"
                   (dragAction)="this.onDragAction($event)"
                   (sortChange)="this.handleSortChange($event)"
                   [totalListCount]="this.totalListCount"
                   (itemContextMenu)="this.onItemContextMenu($event)"
                   (itemMoved)="this.onItemMoved($event)"
                   (filesDropped)="this.onFileDropped($event)"
                   (selectionsSet)="this.setSelections($event)">
    </app-tile-view>
    <app-grid-view *ngIf="this.View==='grid'"
                   [list]="this.ListItems"
                   [listType]="listType"
                   [listItemsAreDraggable]="listItemsAreDraggable"
                   [defaultSortColumn]="defaultSortColumn"
                   [filterTerm]="this.FilterTerm"
                   [selectedItemId]="selectedId"
                   [itemActionButtons]="buttonConfig?.item_actions"
                   [columnsConfig]="columnsConfig"
                   [typeSortOrderConfig]="typeSortOrderConfig"
                   [parentTitle]="title"
                   [hasCheckboxes]="this.hasCheckboxes"
                   [maxSelection]="this.maxSelection"
                   [renderNameAsLink]="this.renderNameAsLink"
                   (exceededMaxSelection)="this.exceededMaxSelection.emit()"
                   [hideActionButtons]="this.hideActionButtons"
                   [currentFolder]="this.currentFolder"
                   [configuredStructureFields]="this.configureStructureFields"
                   (editClick)="editClick.emit($event)"
                   (itemActionClick)="itemActionClick.emit($event)"
                   (dragAction)="this.onDragAction($event)"
                   (sortChange)="this.handleSortChange($event)"
                   [totalListCount]="this.totalListCount"
                   (itemContextMenu)="this.onItemContextMenu($event)"
                   (itemMoved)="this.onItemMoved($event)"
                   (filesDropped)="this.onFileDropped($event)"
                   (selectionsSet)="this.setSelections($event)">
    </app-grid-view>
    <div [ngClass]="{'hidden':!DisplayInfiniteScrollSentinel}" #infiniteScrollSentinel class="infinite-scroll-sentinel">
        <div class="loading-more" (click)="forceLoadMore()">Load more...</div>
    </div>
</div>
