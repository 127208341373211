import { DropEvent } from '../../../shared/external-libraries/ng-drag-drop/shared/drop-event.model';

import { IListItem } from '../../interfaces/ilist-item.interface';

// 02-01-2021 note:  defining class TrashConfig in case
//                   we ever want to handle trash can/delete
//                   configuration options.
//
//                   Also trying to remain consistent with
//                   the precedent of ButtonConfig, the class
//                   used to define a list view button
//                   configuration.
//
//                   Also define a delete event class.
//TEAMS-424: KLW - Make changes to pass an optional parameter for a group of items
export class TrashDeleteEvent {
    //Kevin
    public originalEvent: any;
    public items: any[];
    //public items; 

    //public constructor(originalEventParam: DropEvent, itemParam: IListItem, itemsParam = null) {
    //    this.originalEvent = originalEventParam;
    //    this.item = itemParam;
    //    this.items = itemsParam;

    //    return;
    //}


    //Kevin
    public constructor(originalEventParam: any, itemsParam: any[]) {
        this.originalEvent = originalEventParam;
        //this.item = itemParam;
        this.items = itemsParam;

        return;
    }
}

 

export class TrashConfig {
    // Properties.
    private bCanDelete: boolean = false;

    // Constructor.
    public constructor(bCanDeleteParam: boolean) {
        this.bCanDelete = bCanDeleteParam;
        return;
    }

    // Getter method.
    public get CanDelete(): boolean {
        return (this.bCanDelete);
    }
}
