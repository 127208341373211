import { FormField } from "../form-builder/form-field.model";
import { FormInstanceElement } from "../form-builder/form-instance-element.model";
import { FormInstance } from "../site-content/form-instance.model";

export interface FieldIdToSelectedValues { [key: number]: string[]}

export interface FieldIdAndNameToSelectedValues { [key: string]: string[] }

export class FlexibleSelectionFieldInstructionsRequest {
    public formInstance: FormInstance;
    public formInstanceElement: FormInstanceElement;
    public fieldIdsToSelectedValues: FieldIdToSelectedValues;
    public gridRowId: number = 0
    public gridColumnDefs: FormField[];
    public requestIsForInitialLoadOfGridFormField: boolean;

    public constructor(
        formInstance: FormInstance,
        formInstanceElement: FormInstanceElement,
        fieldIdsToSelectedValues: FieldIdToSelectedValues,
        gridRowId: number,
        gridColumnDefs: FormField[],
        requestIsForInitialLoadOfGridFormField: boolean = false) {

        this.formInstance = formInstance;
        this.formInstanceElement = formInstanceElement;
        this.fieldIdsToSelectedValues = fieldIdsToSelectedValues;
        this.gridRowId = gridRowId;
        this.gridColumnDefs = gridColumnDefs;
        this.requestIsForInitialLoadOfGridFormField = requestIsForInitialLoadOfGridFormField;
    }
}
