import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { plainToClass } from 'class-transformer';
import { environment } from '../../../environments/environment';
import { AsyncJob } from '../models/async-job.model';
import { DocumentPublishingSettings } from '../models/document-publishing-settings.model';
import { CollectApiServiceBase } from './collect-api-base.service';
import { ProgressIndicatorService } from './progress-indicator.service';

@Injectable()
export class DocumentPublishingService extends CollectApiServiceBase<DocumentPublishingSettings> {
    constructor(http: HttpClient, progressIndicatorService: ProgressIndicatorService) {
        super(http, progressIndicatorService, environment.apiUrl, 'documentpublishing', DocumentPublishingSettings);
    }

    public getDocumentPublishingSettingsForSite(siteId: number, forPicker: boolean = false): Promise<any> {
        let url = `${this.url}/${this.endpoint}/SettingsForSite/${siteId}`
        if (forPicker) {
            url = `${url}?forPicker=true`;
        }
        return this.http.get<any>(url)
            .toPromise()
            .then(response => {
                let ret = [];
                for (let a of response) {
                    ret.push(this.formatResponse(a))
                }
                return ret;
            });
    }

    public getDocumentPublishingSettings(id: number): Promise<DocumentPublishingSettings> {
        let url = `${this.url}/${this.endpoint}/Settings/${id}`;
        return this.http.get<DocumentPublishingSettings>(url)
            .toPromise()
            .then(res => {
                return this.formatResponse(res);
            });
    }

    public publish(entityType: string, entityId: number, exportId: number): Promise<AsyncJob> {
        let url = `${this.url}/${this.endpoint}/${entityType}/${entityId}/${exportId}`;
        return this.http.get<AsyncJob>(url)
            .toPromise()
            .then(res => {
                return plainToClass(AsyncJob, res);
            });
    }

    // pharvey - 5/15/2024 - added for VNEXT-1289
    public publishMultiple(entityType: string, entityIds: number[], exportId: number): Promise<AsyncJob> {
        let url = `${this.url}/${this.endpoint}/${entityType}/${exportId}`;
        return this.http.post<AsyncJob>(url, entityIds)
            .toPromise()
            .then(res => {
                return plainToClass(AsyncJob, res);
            });
    }

    public getPublishedDocUrl(asyncJob: AsyncJob, entityType: string, entityId: number, settingsId: number): string {
        let url = `${this.url}/${this.endpoint}/GetPublishedDoc/${entityType}/${entityId}/${asyncJob.jobKey}/${settingsId}`;
        return (url);
    }

    public saveDocumentPublishingSettings(exportSettings: DocumentPublishingSettings): Promise<any> {
        let url = `${this.url}/${this.endpoint}/SaveStylesheet`;
        //KEvin
        // return this.http.post(url, exportSettings).toPromise<any>().then(x => {
        //     return this.formatResponse(x);
        // });


        return this.http.post<any>(url, exportSettings).toPromise().then(x => {
            return this.formatResponse(x);
        });
    }

    public deleteDocumentPublishingSettings(id: number): Promise<number> {
        let url = `${this.url}/${this.endpoint}/DeleteStylesheet/${id}`;
        return this.http.delete(url).toPromise().then(x => {
            return id;
        });
    }
}
