<table mat-table
       [dataSource]="DataSource"
       matSort
       (matSortChange)="saveSortState($event)"
       matSortActive="{{MatSortActive}}"
       matSortDirection="{{MatSortDirection}}"
       (keydown)="onKeyDown($event)">

    <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef class="mat-header-checkbox-padding">
            <mat-checkbox [ngClass]="{'selected-show':(selection.hasValue() && !isAllSelected()) || (selection.hasValue() && isAllSelected())}"
                          class="rounded-checkbox"
                          (change)="$event ? masterToggle() : null"
                          title="{{selectAllTitle}}"
                          [checked]="selection.hasValue() && isAllSelected()">
            </mat-checkbox>
        </th>

        <td mat-cell *matCellDef="let row" class="td-checkbox">
            <div droppable
                 (dragover)="onDragOverRepositionAbove($event, row)"
                 (drop)="this.onDropOnRepositionAbove($event, row)"
                 class="reorder-drop-zone {{getRepositionAboveClass(row)}}"></div>

            <div class="select-container" (dragover)="clearReposition()">
                <mat-icon *ngIf="listItemsAreDraggable" class="drag-indicator">drag_indicator</mat-icon>
                <mat-checkbox [ngClass]="{'selected-show':selection.isSelected(row)}"
                              class="mat-checkbox-padding rounded-checkbox"
                              (click)="$event.stopPropagation(); this.setSelectedRow(row)"
                              (change)="selectionChange($event, row)"
                              [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </div>
            <div droppable
                 (drop)="this.onDropOnRepositionBelow($event, row)"
                 (dragover)="onDragOverRepositionBelow($event, row)"
                 class="reorder-drop-zone {{getRepositionBelowClass(row)}}"></div>
        </td>
    </ng-container>

    <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{GetColumnDisplayName('position')}}</th>
        <td mat-cell *matCellDef="let row" title="The order in which Items will appear when published">
            <div droppable (dragover)="onDragOverRepositionAbove($event, row)" class="reorder-drop-zone"></div>
            <div (dragover)="clearReposition()">{{row.getPosition()}}</div>
            <div droppable (dragover)="onDragOverRepositionBelow($event, row)" class="reorder-drop-zone"></div>
        </td>
    </ng-container>

    <!--
        NOTE/WARNING: If you want action buttons the component using app-list-view MUST supply the name column
        TODO: consider a dedicated actions column
    -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            <div class="nameHeader">{{GetColumnDisplayName('name') }}</div>
        </th>
        <td mat-cell *matCellDef="let row">
            <div droppable (dragover)="onDragOverRepositionAbove($event, row)" class="reorder-drop-zone"></div>
            <div class="align" (dragover)="clearReposition()">
                <i class="grid-icon" [appIcon]="row"></i>

                <a *ngIf="renderNameAsLink"
                   tabindex="0"
                   class="edit-hlink"
                   title="{{this.getItemTitle(row)}}"
                   (click)="onEditClick($event, row); this.itemSelected(row)"
                   (keyup.enter)="onEditClick($event, row); this.itemSelected(row)">
                    {{row.getName()}}
                </a>

                <span *ngIf="!renderNameAsLink" (click)="onEditClick($event, row); this.itemSelected(row)">
                    {{row.getName()}}
                </span>

                <ng-container *ngFor="let btn of itemActionButtons">
                    <ng-container *ngIf="btn.appliesTo(row)">
                        <mat-icon *ngIf="btn.iconFormatFor(row) == 'MatIcon'"
                                  [ngClass]="{selected:selectedItemId && false && generateItemActionId(row, btn) === selectedItemActionId}"
                                  title="{{getActionButtonTitle(btn, row)}}"
                                  tabindex="0"
                                  (click)="onItemActionClick($event, row, btn)"
                                  (keyup.enter)="onItemActionClick($event, row, btn)"
                                  class="show_details"
                                  [class]="this.hideActionButtonsClass">
                            {{btn.iconFor(row)}}
                        </mat-icon>

                        <mat-icon *ngIf="btn.iconFormatFor(row) == 'SvgIcon'"
                                  svgIcon="{{btn.iconFor(row)}}"
                                  [ngClass]="{selected:selectedItemId && generateItemActionId(row, btn) === selectedItemActionId}"
                                  title="{{getActionButtonTitle(btn, row)}}"
                                  tabindex="0"
                                  (click)="onItemActionClick($event, row, btn)"
                                  (keyup.enter)="onItemActionClick($event, row, btn)"
                                  class="show_details"
                                  [class]="this.hideActionButtonsClass"></mat-icon>
                    </ng-container>
                    <ng-container *ngIf="!btn.appliesTo(row)">
                        <mat-icon class="blank"></mat-icon>
                    </ng-container>
                </ng-container>
            </div>
            <div droppable (dragover)="onDragOverRepositionBelow($event, row)" class="reorder-drop-zone"></div>
        </td>
    </ng-container>
    <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{GetColumnDisplayName('description')}} </th>
        <td mat-cell *matCellDef="let row"> {{row.getExtraInfo()}} </td>
    </ng-container>
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{GetColumnDisplayName('status')}} </th>
        <td mat-cell *matCellDef="let row"> {{row.getStatus()}} </td>
    </ng-container>
    <ng-container matColumnDef="childCount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{GetColumnDisplayName('childCount')}} </th>
        <td mat-cell *matCellDef="let row"> {{row.getChildCount() > 0 ? row.getChildCount() : ''}}</td>
    </ng-container>
    <ng-container matColumnDef="modifiedBy">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{GetColumnDisplayName('modifiedBy')}} </th>
        <td mat-cell *matCellDef="let row"> {{row.getModifiedBy()}}</td>
    </ng-container>
    <ng-container matColumnDef="modifiedDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{GetColumnDisplayName('modifiedDate')}} </th>
        <td mat-cell *matCellDef="let row"> {{row.getModifiedDate() | date: 'medium'}} </td>
    </ng-container>
    <!--//TEAMS-424: KLW - Add in columns to account for Attachments-->
    <ng-container matColumnDef="createdByName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{GetColumnDisplayName('createdByName')}} </th>
        <td mat-cell *matCellDef="let row"> {{row.createdByName}} </td>
    </ng-container>
    <ng-container matColumnDef="createdDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{GetColumnDisplayName('createdDate')}} </th>
        <td mat-cell *matCellDef="let row"> {{row.createdDate | date: 'medium'}} </td>
    </ng-container>

    <!-- pjh - 10/11/2022 - introduction of a dynamic columns. See comment at top of the component.ts file-->
    <ng-container *ngFor="let col of PropertyCols" matColumnDef="{{col.id}}">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{col.propertyName}} </th>
        <td mat-cell *matCellDef="let row"> {{row.getValue(col.propertyName)}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="DisplayedColumns" class="hovered"></tr>

    <!-- Kevin -->
    <tr mat-row
        *matRowDef="let row; columns: DisplayedColumns;"
        (drop)="this.dropGridOrTileObject($event, row)"
        [ngClass]="{selected:row.id===selectedItemId }"
        (contextmenu)="onItemContextMenu($event, row, this.selectedItems(this.selection)); this.onRightClick(row)"
        draggable
        droppable 
        [dropScope]="'item'"
        [dragScope]="'item'"
        [dragData]="row"
        (onDragStart)="this.GridItemDragged(row); this.onItemDragStart($event, this.selectedItems(this.selection, row))"
        (onDragEnd)="this.onItemDragEnd($event, this.selectedItems(this.selection, row))"
        (onDrop)="this.onDragDrop($event,row)"
        tabindex="0"
        (click)="this.rowClicked($event, row)"
        class="hovered {{getExpandRowForReorderingClass(row)}}"
        [class.no-border]="this.isTypeFormInstance(row) || this.isTypeWorkflow(row)"
        [class.Attachment]="this.isTypeAttachment(row)">
    </tr>


</table>
