<ng-container *ngIf="!isFootnotesGrid">
    <div class="kendo-grid-wrapper">
        <ng-container *ngIf="!this.HideFormFieldBody">
            <!-- VNEXT-1540: KLW - Add the resizable attribute to the Kendo grid to resize the columns when editing -->
            <kendo-grid *ngIf="FormFieldId"
                        kendoGridRemoteBinding
                        (notifyLoaded)="Helper.handleLoaded($event)"
                        [gridConfig]="this.gridFormFieldComponent.GridConfig"
                        [fieldDefinitionService]="this.FieldDefinitionService"
                        [getCellDisplayValue]="this.GridFormFieldComponent"
                        [formInstanceElementId]="this.FormInstanceElementId"
                        [kendoGridService]="this.KendoGridService"
                        [rowReorderable]="true"
                        [pageSize]="VirtualScrollingPageSize"
                        [skip]="VirtualScrollingSkip"
                        [sort]="this.dataUsedWithKendoGrid.gridState.sort"
                        scrollable="virtual"
                        [rowHeight]="23.14"
                        [height]="this.GetGridHeight()"
                        [selectable]="true"
                        [sortable]="this.SortSettings"
                        [filterable]="this.GridIsFilterable"
                        [navigable]="true"
                        (cellClick)="handleEditCellClick($event)"
                        (edit)="handleEdit($event)"
                        (cancel)="handleCancel($event)"
                        (cellClose)="handleCellClose($event)"
                        (save)="handleAddOrUpdate($event)"
                        (remove)="handleRemove($event)"
                        (add)="addRowToUI($event)"
                        (rowReorder)="handleRowReorder($event)"
                        (detailExpand)="handleDetailExpand($event)"
                        (detailCollapse)="handleDetailCollapse($event)"
                        [(filter)]="Helper.Filters"
                        (filterChange)="filterChange()">

                <ng-template kendoGridToolbarTemplate>

                    <button [disabled]="ModifyRowDisabled || NewRowDisabled" style="margin-left:4px; padding:4px; height:30px;" kendoGridAddCommand (click)="this.addValidationAndFlagForNewKendoRow()">
                        <mat-icon aria-hidden="false" aria-label="Add grid row" class="add-row-mat-icon" title="Add row">add</mat-icon>
                    </button>

                    <div *ngIf="GridIsFilterable">
                        <button *ngIf="!ShowGridFilter" style="margin-left:4px; padding:4px; height:30px;"
                                class="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md"
                                (click)="this.turnOnGridFilter()">
                            <mat-icon aria-hidden="false" aria-label="Click to enable filter and sort" title="Click to enable filter and sort">filter_alt</mat-icon>
                        </button>

                        <button *ngIf="ShowGridFilter" style="margin-left:4px; padding:4px; height:30px;"
                                class="k-button k-button-md k-button-solid k-button-solid-base k-rounded-md"
                                (click)="this.turnOffGridFilter()">
                            <mat-icon aria-hidden="false" aria-label="Click to disable filter and sort" title="Click to disable filter and sort">filter_alt_off</mat-icon>
                        </button>

                        <ng-container *ngIf="ShowGridFilter">
                            <button *ngFor="let preset of this.FilterPresets"
                                    mat-flat-button
                                    color="primary"
                                    class="preset-button"
                                    (click)="selectPresetFilter(preset)"
                                    title="{{preset.filterName}}">
                                <mat-icon>filter_alt</mat-icon>
                                {{preset.filterName}}
                            </button>
                        </ng-container>
                    </div>

                    <kendo-grid-spacer></kendo-grid-spacer>

                    <button *ngIf="ShowGeocodeGrid()" mat-button (click)="this.geoCodeGrid();">
                        <mat-icon aria-hidden="false" aria-label="Geocode address data in grid" class="geocode-data-mat-icon" title="Geocode address data in grid">language</mat-icon>
                    </button>

                    <button mat-button (click)="this.showPasteAreaKendo();">
                        <mat-icon aria-hidden="false" aria-label="Paste from Excel into grid" class="paste-data-mat-icon" title="Paste from Excel into grid">content_paste</mat-icon>
                    </button>

                    <button mat-button (click)="this.exportGridDataClicked()">
                        <mat-icon aria-hidden="false" aria-label="Download grid data" class="export-grid-data-mat-icon" title="Download grid data" svgIcon="microsoft-excel"></mat-icon>
                    </button>

                    <ng-container>
                        <input type="file"
                               accept=".xlsx,.xls"
                               #selectExcelFile
                               class="hidden-file-input"
                               (change)="this.onExcelFileChanged($event)" />

                        <button class="grid-button" mat-button (click)="this.importGridDataClicked()">
                            <mat-icon aria-hidden="false" aria-label="Upload grid data from Excel file" title="Upload grid data from Excel">upload_file</mat-icon>
                        </button>
                    </ng-container>

                    <ng-container *ngIf="Helper.IsBeingFiltered">
                        <button class="clear-all-filters-btn" mat-stroked-button (click)='Helper.handleClearFilters()'>Clear All Filters</button>
                        {{Helper.ShowingXofN}}
                    </ng-container>

                </ng-template>

                <kendo-grid-rowreorder-column *ngIf="!HasFixedRowHeadings" [width]=30></kendo-grid-rowreorder-column>
                <ng-container *ngFor="let columnDef of this.GridColumnDefs; let iColIndex=index">
                    <kendo-grid-column *ngIf="ShowColumnInRow(columnDef)"
                                       field="{{columnDef.name}}"
                                       title="{{columnDef.displayName != null ? columnDef.displayName : columnDef.name}}"
                                       filter="{{FilterType(columnDef)}}"
                                       [editable]="!this.IsReadOnlyGrid"
                                       [filterable]="ColumnIsFilterable(columnDef)"
                                       [sortable]="IsSortable(columnDef)"
                                       [width]="GetColumnWidth(columnDef)">

                        <!-- Added to enable use of title attribute for hover text -->
                        <ng-template kendoGridHeaderTemplate let-column let-columnIndex="columnIndex">
                            <span title="{{column.title}}" class="column-header">
                                {{column.title}}
                            </span>
                        </ng-template>

                        <ng-template kendoGridCellTemplate let-dataItem>
                            <span title="{{getCellDisplayValueForKendo(dataItem, columnDef, iColIndex)}}">
                                <!-- Some modifications here to allow cells to be displayed in different ways - span (the default) link, button -->
                                <span *ngIf="ShowColumnAs(columnDef) == 'SPAN'" [innerHTML]="getCellDisplayValueForKendo(dataItem, columnDef, iColIndex)"></span>

                                <a *ngIf="ShowColumnAs(columnDef) == 'LINK'" class="html-display"
                                   href="{{getCellDisplayValueForKendo(dataItem, columnDef, iColIndex)}}"
                                   target="_blank">{{getCellDisplayValueForKendo(dataItem, columnDef, iColIndex)}}</a>

                                <!-- New for UrlBuilder field -->
                                <button *ngIf="ShowColumnAs(columnDef) == 'BUTTON'"
                                        kendoButton style="padding:2px;font-size:8pt;"
                                        [disabled]="!allParamsSuppliedForUrlBuilder(dataItem, columnDef, iColIndex)"
                                        (click)="openUrl(getCellDisplayValueForKendo(dataItem, columnDef, iColIndex))">
                                    {{ButtonLabel(columnDef)}}
                                </button>
                            </span>

                            <ng-container *ngIf="this.ShowNumericTotalsFooter">
                                <ng-template kendoGridFooterTemplate>
                                    {{ this.getNumericFooterTotalValueFor(iColIndex, columnDef) | number}}
                                </ng-template>
                            </ng-container>
                        </ng-template>

                        <ng-template kendoGridEditTemplate let-dataItem>
                            <app-form-field-wrapper [formField]="columnDef"
                                                    [formInstance]="FormInstance"
                                                    [mode]="'instance'"
                                                    [readOnly]="false"
                                                    [fieldIsInGrid]="true"
                                                    [gridRowId]="this.DataUsedWithKendoGrid.selectedGridRow?.DatabaseId"
                                                    [gridColumnDefs]="this.GridColumnDefs"
                                                    [(ngModel)]="this.DataUsedWithKendoGrid.selectedGridRow?.FormInstanceElementWrappers[iColIndex].formInstanceElement"
                                                    (onInit)="this.gridColumnInit($event, columnDef)"
                                                    (ngModelChange)="this.gridColumnValueChanged(columnDef,this.DataUsedWithKendoGrid.selectedGridRow?.FormInstanceElementWrappers[iColIndex].formInstanceElement)"
                                                    (formStatusChanged)="handleValidityChange($event)"
                                                    (wrapperFormControlCreated)="this.handleWrapperFormControlCreated($event)"
                                                    (touched)="handleTouched($event)">
                            </app-form-field-wrapper>

                            <ng-container *ngIf="this.ShowNumericTotalsFooter">
                                <ng-template kendoGridFooterTemplate>
                                    {{ this.getNumericFooterTotalValueFor(iColIndex, columnDef) | number }}
                                </ng-template>
                            </ng-container>
                        </ng-template>


                    </kendo-grid-column>
                </ng-container>

                <kendo-grid-command-column [width]="75">
                    <ng-template kendoGridCellTemplate let-isNew="isNew">
                        <button [disabled]="ModifyRowDisabled" class="command-btn" kendoGridEditCommand kendoGridFocusable><mat-icon class="row-action-icon" title="edit">edit</mat-icon></button>
                        <ng-container *ngIf="!this.HideRowDelete">
                            <button [disabled]="ModifyRowDisabled" class="command-btn" kendoGridRemoveCommand kendoGridFocusable><mat-icon class="row-action-icon" title="delete">delete</mat-icon></button>
                        </ng-container>
                        <!--TEAMS-838: KLW - Put in the attribute for validation -->
                        <button class="command-btn" kendoGridSaveCommand kendoGridFocusable [disabled]="this.validationFormGroup"><mat-icon class="row-action" title="Save Row">save</mat-icon></button>
                        <button class="command-btn" kendoGridCancelCommand kendoGridFocusable (click)="this.removeNewKendoRowFlag()"><mat-icon class="row-action" title="cancel">cancel</mat-icon></button>
                    </ng-template>
                </kendo-grid-command-column>

                <!-- Row Expansion to show detail pane -->
                <ng-template *ngIf="GridHasDetail()" kendoGridDetailTemplate let-dataItem>
                    <div #rowDetailPanel>
                        <ng-container *ngFor="let columnDef of this.GridColumnDefs; let iColIndex=index">
                            <ng-container *ngIf="ShowColumnInDetail(columnDef)">
                                <app-form-field-wrapper *ngIf="RowEditInProgress"
                                                        [formField]="columnDef"
                                                        [mode]="'instance'"
                                                        [readOnly]="false"
                                                        [(ngModel)]="this.DataUsedWithKendoGrid.selectedGridRow?.FormInstanceElementWrappers[iColIndex].formInstanceElement"
                                                        (onInit)="this.gridColumnInit($event, columnDef)"
                                                        (ngModelChange)="this.gridColumnValueChanged(columnDef,this.DataUsedWithKendoGrid.selectedGridRow?.FormInstanceElementWrappers[iColIndex].formInstanceElement)"
                                                        (formStatusChanged)="handleValidityChange($event)">
                                </app-form-field-wrapper>
                                <div *ngIf="!RowEditInProgress">
                                    <strong>{{columnDef.displayName}}</strong>: {{ getCellDisplayValueForKendo(dataItem, columnDef, iColIndex) }}
                                </div>
                            </ng-container>
                        </ng-container>
                    </div>

                </ng-template>
            </kendo-grid>
        </ng-container>
        <!--
            <mat-icon aria-hidden="false" aria-label="Download grid data" class="export-grid-data-mat-icon" title="Download grid data" svgIcon="microsoft-excel"></mat-icon>
            <mat-icon aria-hidden="false" aria-label="Upload grid data from Excel file" class="import-grid-data-mat-icon" title="Upload grid data from Excel">upload_file</mat-icon>
        -->
        <ng-container *ngIf="this.HideFormFieldBody">
            <div class="export-import-buttons-only-div">
                <!-- Note:  this <div/> only shows a grid's export and import buttons. -->
                <button mat-button (click)="this.exportGridDataClicked()">
                    <img src="assets/images/v3_download.png" alt="Download to Excel">
                </button>
                <ng-container>
                    <input type="file"
                           accept=".xlsx,.xls"
                           #selectExcelFile
                           class="hidden-file-input"
                           (change)="this.onExcelFileChanged($event)" />

                    <button class="grid-button" mat-button (click)="this.importGridDataClicked()">
                        <img src="assets/images/v3_upload.png" alt="Upload from Excel">
                    </button>
                </ng-container>
            </div>
        </ng-container>
        <div style="height:10px;"><span *ngIf="Saving">Saving...</span></div>
    </div>
</ng-container>
