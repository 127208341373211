import { Injectable } from '@angular/core';
import {
    HttpClient,
    HttpRequest,
    HttpEventType,
    HttpResponse,
    HttpHeaders,
    HttpEvent
} from '@angular/common/http';
import {
    Subject,
    Observable
} from 'rxjs';

import { environment } from '../../../environments/environment';
import { CollectApiServiceBase } from './collect-api-base.service';
import {
    AttachmentCheckoutInfo,
    AttachmentStatus,
    AttachmentInfoWithUrls
} from '../models/attachment/attachment-info-with-urls.model';
import { Attachment, AttachmentProperties } from '../models/attachment/attachment.model';
import { plainToClass } from "class-transformer";
import { AttachmentStatusAndHistory } from '../models/attachment/attachment-status-and-history.model';
import { ProgressIndicatorService } from './progress-indicator.service';

// Note:  the code in this service was derived from the following tutorial:
//        https://malcoded.com/posts/angular-file-upload-component-with-express/.

// Define a file upload progress class.
export class FileUploadProgress {
    // Progress information.
    public fileName: string;
    public progressValue: number = 0;
    public callerData: any;

    // Saved/uploaded file info.
    /*
    public id: number;
    public clientFileName: string;
    public fileMimeType: string;
    public fileSize: number;
    public createdDate: string;
    */
    public attachInfoWithUrls: AttachmentInfoWithUrls = null;
}

// Export a progress hash class.
export interface IFileUploadProgressHash {
    //[fileName: string]: { progress: Observable<number> };
    [fileName: string]: { progress: Observable<FileUploadProgress> };
}

// Define an internally used class.
class FileUploadSubject<T> extends Subject<T> {
    // Properties.
    private fileName: string;

    // Constructor.
    constructor(fileNameParam: string) {
        super();

        this.fileName = fileNameParam;

        return;
    }

    // Methods.
    public get FileName(): string {
        return (this.fileName);
    }
}

@Injectable()
export class AttachmentService extends CollectApiServiceBase<Attachment> {

    public reorderAttachments(attachmentsInDesiredOrder: AttachmentInfoWithUrls[]) {
        var url = `${this.url}/${this.endpoint}/reorderAttachments/`;
        return this.http.post<any[]>(url, attachmentsInDesiredOrder)
            .toPromise()
            .then(members => {
                return members;
            })
            .catch(this.handleError);
    }

    // Constructor.
    constructor(http: HttpClient,
        public progressIndicatorService: ProgressIndicatorService
    ) {
        super(http, progressIndicatorService, environment.apiUrl, 'attachment', Attachment)

        return;
    }

    // Custom service methods.
    public uploadFiles(dataCollectionId: number,
        folderId: number,
        formInstanceId: number,
        formFieldId: number,
        files: Set<File>,
        optionalCallerData = null) {
        // this will be the our resulting map
        //const status: { [key: string]: { progress: Observable<number> } } = {};
        const status: IFileUploadProgressHash = {};

        files.forEach(file => {
            // create a new multipart-form for every file
            const formData: FormData = new FormData();
            formData.append('file', file, file.name);

            // create a http-post request and pass the form
            // tell it to report the upload progress
            var httpHeaders: HttpHeaders = new HttpHeaders();

            // Construct the URL.
            var url = `${this.url}/${this.endpoint}/uploadFiles/${dataCollectionId}/${folderId}/${formInstanceId}/${formFieldId}`;

            // Issue the request.
            const req = new HttpRequest('POST', url, formData, {
                reportProgress: true,

                headers: httpHeaders
            });

            // create a new progress-subject for every file
            //const progress = new Subject<number>();
            //const progress = new Subject<FileUploadProgress>();
            const progress = new FileUploadSubject<FileUploadProgress>(file.name);

            // send the http-request and subscribe for progress-updates
            this.http.request(req).subscribe(event => {
                if (event.type === HttpEventType.UploadProgress) {

                    // calculate the progress percentage
                    const percentDone = Math.round(100 * event.loaded / event.total);

                    // pass the percentage into the progress-stream
                    var fileUploadProgress = new FileUploadProgress();
                    fileUploadProgress.fileName = progress.FileName;
                    fileUploadProgress.progressValue = percentDone;
                    fileUploadProgress.callerData = optionalCallerData;
                    //progress.next(percentDone);

                    progress.next(fileUploadProgress);
                } else if ((event.type === HttpEventType.Sent) ||
                    (event.type === HttpEventType.ResponseHeader) ||
                    (event.type === HttpEventType.DownloadProgress)) {
                    var consoleMsg: string =
                        `AttachmentService.upload():  received an event of type ${event.type}.`;
                    console.log(consoleMsg);
                } else if (event instanceof HttpResponse) {
                    // Close the progress-stream if we get an answer form the API
                    // The upload is complete
                    var fileUploadProgress = new FileUploadProgress();
                    fileUploadProgress.fileName = progress.FileName;
                    fileUploadProgress.progressValue = 100;
                    fileUploadProgress.callerData = optionalCallerData;

                    /*
                    var responseBody = event.body;
                    fileUploadProgress.id = responseBody['id'];
                    fileUploadProgress.clientFileName = responseBody['clientFileName'];
                    fileUploadProgress.fileMimeType = responseBody['fileMimeType'];
                    fileUploadProgress.fileSize = responseBody['fileSize'];
                    fileUploadProgress.createdDate = responseBody['createdDate'];
                    */

                    var attachmentEntry: AttachmentInfoWithUrls = null;
                    var arrAttachmentInfo: AttachmentInfoWithUrls[] =
                        <AttachmentInfoWithUrls[]><unknown>event.body;
                    if (arrAttachmentInfo && (arrAttachmentInfo.length > 0)) {
                        var arrAttachmentEntry: AttachmentInfoWithUrls[] =
                            arrAttachmentInfo.filter(ai => ai.fileName == progress.FileName);

                        if (arrAttachmentEntry && (arrAttachmentEntry.length == 1)) {
                            attachmentEntry = arrAttachmentEntry[0];
                        }
                    }

                    if (attachmentEntry != null) {
                        fileUploadProgress.attachInfoWithUrls = attachmentEntry;
                    }

                    progress.next(fileUploadProgress);

                    progress.complete();
                } else {
                    console.log("AttachmentService.upload():  received an event of an unknown type.")
                }
            });

            // Save every progress-observable in a map of all observables
            status[file.name] = {
                progress: progress.asObservable()
            };
        });

        // return the map of progress.observables
        return (status);
    }

    //public upload(files: Set<File>): { [key: string]: { progress: Observable<number> } } {
    public upload(files: Set<File>, optionalCallerData = null): IFileUploadProgressHash {
        // this will be the our resulting map
        //const status: { [key: string]: { progress: Observable<number> } } = {};
        const status: IFileUploadProgressHash = {};

        var url = `${this.url}/${this.endpoint}/upload`;

        files.forEach(file => {
            // create a new multipart-form for every file
            const formData: FormData = new FormData();
            formData.append('file', file, file.name);

            // create a http-post request and pass the form
            // tell it to report the upload progress
            var httpHeaders: HttpHeaders = new HttpHeaders();
            //httpHeaders.append('fileName', file.name);

            const req = new HttpRequest('POST', url, formData, {
                reportProgress: true,

                headers: httpHeaders
            });

            // create a new progress-subject for every file
            //const progress = new Subject<number>();
            //const progress = new Subject<FileUploadProgress>();
            const progress = new FileUploadSubject<FileUploadProgress>(file.name);

            // send the http-request and subscribe for progress-updates
            this.http.request(req).subscribe(event => {
                if (event.type === HttpEventType.UploadProgress) {

                    // calculate the progress percentage
                    const percentDone = Math.round(100 * event.loaded / event.total);

                    // pass the percentage into the progress-stream
                    var fileUploadProgress = new FileUploadProgress();
                    fileUploadProgress.fileName = progress.FileName;
                    fileUploadProgress.progressValue = percentDone;
                    fileUploadProgress.callerData = optionalCallerData;
                    //progress.next(percentDone);

                    progress.next(fileUploadProgress);
                } else if ((event.type === HttpEventType.Sent) ||
                    (event.type === HttpEventType.ResponseHeader) ||
                    (event.type === HttpEventType.DownloadProgress)) {
                    var consoleMsg: string =
                        `AttachmentService.upload():  received an event of type ${event.type}.`;
                    console.log(consoleMsg);
                } else if (event instanceof HttpResponse) {
                    // Close the progress-stream if we get an answer form the API
                    // The upload is complete
                    var fileUploadProgress = new FileUploadProgress();
                    fileUploadProgress.fileName = progress.FileName;
                    fileUploadProgress.progressValue = 100;
                    fileUploadProgress.callerData = optionalCallerData;

                    var responseBody = event.body;

                    /*
                    fileUploadProgress.id = responseBody['id'];
                    fileUploadProgress.clientFileName = responseBody['clientFileName'];
                    fileUploadProgress.fileMimeType = responseBody['fileMimeType'];
                    fileUploadProgress.fileSize = responseBody['fileSize'];
                    fileUploadProgress.createdDate = responseBody['createdDate'];
                    */
                    var attachmentEntry: AttachmentInfoWithUrls = null;
                    var arrAttachmentInfo: AttachmentInfoWithUrls[] =
                        <AttachmentInfoWithUrls[]><unknown>event.body;
                    if (arrAttachmentInfo && (arrAttachmentInfo.length > 0)) {
                        var arrAttachmentEntry: AttachmentInfoWithUrls[] =
                            arrAttachmentInfo.filter(ai => ai.fileName == progress.FileName);

                        if (arrAttachmentEntry && (arrAttachmentEntry.length == 1)) {
                            attachmentEntry = arrAttachmentEntry[0];
                        }
                    }

                    if (attachmentEntry != null) {
                        fileUploadProgress.attachInfoWithUrls = attachmentEntry;
                    }

                    progress.next(fileUploadProgress);

                    progress.complete();
                } else {
                    console.log("AttachmentService.upload():  received an event of an unknown type.")
                }
            });

            // Save every progress-observable in a map of all observables
            status[file.name] = {
                progress: progress.asObservable()
            };
        });

        // return the map of progress.observables
        return (status);
    }

    //TEAMS-424: KLW - new method to delete an attachment
    public deleteAttachment(dataCollectionId: number,
        folderId: number,
        formInstanceId: number,
        formFieldId: number,
        fileName: string): Promise<boolean> {

        var url = `${this.url}/${this.endpoint}/delete/${dataCollectionId}/${folderId}/${formInstanceId}/${formFieldId}/${fileName}`;

        var httpHeaders: HttpHeaders = new HttpHeaders();

        const req = new HttpRequest('POST', url, {
            headers: httpHeaders
        });

        return this.http.post(url, req).toPromise().then(event => {
            if (event == 'true')
                return true;
            else
                return false;
        });
    }

    public setCustomProperties(dataCollectionId: number,
        folderId: number,
        formInstanceId: number,
        formFieldId: number,
        attachmentName: string,
        customProps: AttachmentProperties): Promise<any[]> {
        var url = `${this.url}/${this.endpoint}/setCustomProperties/${dataCollectionId}/${folderId}/${formInstanceId}/${formFieldId}/${attachmentName}`;

        return this.http.put<any[]>(url, customProps)
            .toPromise()
            .then(res => {
                return res;
            })
            .catch(this.handleError);
    }

    public updateDescription(dataCollectionId: number,
        folderId: number,
        formInstanceId: number,
        formFieldId: number,
        attachmentName: string,
        newDescription: string) {
        var url = `${this.url}/${this.endpoint}/updateDescription/${dataCollectionId}/${folderId}/${formInstanceId}/${formFieldId}/${attachmentName}/${newDescription}`;

        var httpHeaders: HttpHeaders = new HttpHeaders();

        const req = new HttpRequest('POST', url, {
            headers: httpHeaders
        });

        return this.http.post(url, req).toPromise().then(event => {
            if (event == 'true')
                return true;
            else
                return false;
        });
    }

    public renameAttachment(dataCollectionId: number,
        folderId: number,
        formInstanceId: number,
        formFieldId: number,
        attachmentName: string,
        newAttachmentName: string): Promise<boolean> {

        var url = `${this.url}/${this.endpoint}/rename/${dataCollectionId}/${folderId}/${formInstanceId}/${formFieldId}/${attachmentName}/${newAttachmentName}`;

        var httpHeaders: HttpHeaders = new HttpHeaders();

        const req = new HttpRequest('POST', url, {
            headers: httpHeaders
        });

        return this.http.post(url, req).toPromise().then(event => {
            if (event == 'true')
                return true;
            else
                return false;
        });
    }

    //TEAMS-424: KLW - new method to get all attachment history and status
    public getAttachmentHistories(dataCollectionId: number,
        folderId: number,
        formInstanceId: number,
        formFieldId: number): Promise<AttachmentStatusAndHistory[]> {

        var url = `${this.url}/${this.endpoint}/histories/${dataCollectionId}/${folderId}/${formInstanceId}/${formFieldId}`;

        return this.http.get<AttachmentStatusAndHistory[]>(url).toPromise().then(response => {
            if (response)
                return response;
            else
                return null;
        });
    }

    //TEAMS-424: KLW - new method to see if the attachment service should actually use attachments.
    public getCanUseAttachments(): Promise<boolean> {

        var url = `${this.url}/${this.endpoint}/GetCanUseAttachments/`;

        return this.http.get<boolean>(url).toPromise().then(response => {
            return response;
        });
    }

    formatResponse(data: Attachment): Attachment {
        var obj = plainToClass(Attachment, data);

        return (obj);
    }

    save(data: Attachment): Promise<Attachment> {
        console.log(data.id);
        if (data.id > 0) {
            return (this.update(data));
        } else
            return (this.create(data));
    }
}
