import { Injectable } from '@angular/core';
import { DropEvent } from '../../shared/external-libraries/ng-drag-drop/shared/drop-event.model';
import { DragDropEnum } from '../enums/drag-drop.enum';
import { IDragDropItem } from '../interfaces/drag-drop-items.interface';
import { IListItem } from '../interfaces/ilist-item.interface';

@Injectable()
export class DragDropService {

    public setItems(event: DragEvent, items: IListItem[]): DragEvent {
        let mappedItems = items.map((x) => {
            return {
                id: x.getId(),
                type: x.getType(),
                label: x.name

            } as IDragDropItem
        });

        event.dataTransfer.items.clear();

        event.dataTransfer.items.add(JSON.stringify(mappedItems), DragDropEnum.TEXT_FORMAT);

        return event;
    }

    //Kevin
    public getItems(event: any): Promise<IDragDropItem[]> { 
        return new Promise<IDragDropItem[]>((resolve) => {

            let dragEvent: DragEvent = event.nativeEvent;

            let items: DataTransferItemList = dragEvent.dataTransfer.items;


            items[0].getAsString(function (s) {
                let retVal: IDragDropItem[] = JSON.parse(s) as IDragDropItem[];
                resolve(retVal);
            }); 
        });
    } 
}

